/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
import * as types from "./graphql";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 *
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
	"\n\t\tmutation SettingsUpdate($settings: UserSettingsUpdateInput!) {\n\t\t\tselfUpdate(input: { data: { settings: $settings } }) {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t":
		types.SettingsUpdateDocument,
	"\n\t\tquery HeaderProfile {\n\t\t\tme {\n\t\t\t\tid\n\t\t\t\tgivenName\n\t\t\t\tsurname\n\t\t\t\temail\n\t\t\t}\n\t\t}\n\t":
		types.HeaderProfileDocument,
	"\n\t\tquery PrivacyStatementAlert($service: String!) {\n\t\t\tactivePrivacyStatement(service: $service) {\n\t\t\t\tid\n\t\t\t\tpublishedAt\n\t\t\t\tversion\n\t\t\t\tcontent\n\t\t\t}\n\t\t}\n\t":
		types.PrivacyStatementAlertDocument,
	"\n\t\t\tquery CNMIMCalculateSodium($formulas: [CNMIMSodiumFormulaInput!]!) {\n\t\t\t\tCNMIMCalculateSodium(formulas: $formulas) {\n\t\t\t\t\tformulationId\n\t\t\t\t\tsodium\n\t\t\t\t}\n\t\t\t}\n\t\t":
		types.CnmimCalculateSodiumDocument,
	"\n\t\t\tmutation LogActivity($input: ActivityCreateInput!) {\n\t\t\t\tlogActivity(input: $input)\n\t\t\t}\n\t\t":
		types.LogActivityDocument,
	"\n\tquery SolveCalculation(\n\t\t$formulas: [CNMIMMoldGrowthFormulaInput!]!\n\t\t$reference: CNMIMMoldGrowthReferenceFormulaInput!\n\t\t$regionHandle: String!\n\t\t$productName: String!\n\t\t$additionalInformation: String\n\t\t$foodType: String\n\t) {\n\t\tCNMIMCalculateMoldGrowth(\n\t\t\tformulas: $formulas\n\t\t\treference: $reference\n\t\t\tregionHandle: $regionHandle\n\t\t\tproductName: $productName\n\t\t\tadditionalInformation: $additionalInformation\n\t\t\tfoodType: $foodType\n\t\t) {\n\t\t\tid\n\n\t\t\tmoldGroupResults {\n\t\t\t\ttype\n\t\t\t\tminPredictedDays\n\t\t\t\twarning\n\t\t\t\tmoldGrowthFormulations {\n\t\t\t\t\tid\n\t\t\t\t\tisolatedFrom\n\t\t\t\t\tpredictedDays\n\t\t\t\t\tname\n\t\t\t\t\tstrainCode\n\t\t\t\t\twarning\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.SolveCalculationDocument,
	"\n\t\t\t\tquery PermissionsHaveChanged {\n\t\t\t\t\tmyPermissionsHaveChanged\n\t\t\t\t}\n\t\t\t":
		types.PermissionsHaveChangedDocument,
	"\n\t\t\t\tquery PrivacyStatementHasAccepted {\n\t\t\t\t\thasAcceptedPrivacyStatement\n\t\t\t\t}\n\t\t\t":
		types.PrivacyStatementHasAcceptedDocument,
	"\n\t\t\t\tmutation AcceptPrivacyStatement {\n\t\t\t\t\tacceptPrivacyStatement\n\t\t\t\t}\n\t\t\t":
		types.AcceptPrivacyStatementDocument,
	"\n\t\t\tquery PortfolioProducts($regionHandle: String!) {\n\t\t\t\tCNMIMPortfolio(input: { regionAvailable: { handle: $regionHandle } }) {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t":
		types.PortfolioProductsDocument,
	"\n\t\t\t\tquery Settings {\n\t\t\t\t\tme {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tsettings {\n\t\t\t\t\t\t\tdefaultUnitOfWeight\n\t\t\t\t\t\t\tservingSizeInGrams\n\t\t\t\t\t\t\tcnmimSettings\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t":
		types.SettingsDocument,
	"\n\t\tquery CNMIMReferenceFormulaDefaultDaysToVisualMoldGrowth {\n\t\t\tCNMIMReferenceFormulaDefaultDaysToVisualMoldGrowth\n\t\t}\n\t":
		types.CnmimReferenceFormulaDefaultDaysToVisualMoldGrowthDocument,
	"\n\t\tquery ConfigureRegions {\n\t\t\tregions {\n\t\t\t\tid\n\t\t\t\thandle\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t":
		types.ConfigureRegionsDocument,
	"\n\t\tquery ActivePrivacyStatement($service: String!) {\n\t\t\tactivePrivacyStatement(service: $service) {\n\t\t\t\tid\n\t\t\t\tpublishedAt\n\t\t\t\tversion\n\t\t\t\tcontent\n\t\t\t}\n\t\t}\n\t":
		types.ActivePrivacyStatementDocument,
	"\n\t\tquery ResultProfile {\n\t\t\tme {\n\t\t\t\tid\n\t\t\t\tgivenName\n\t\t\t\tsurname\n\t\t\t}\n\t\t}\n\t":
		types.ResultProfileDocument,
	"\n\t\tquery PortfolioList {\n\t\t\tCNMIMPortfolio {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t":
		types.PortfolioListDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 *
 * @example
 * 	```ts
 * 	const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * 	```
 *
 * 	The query argument is unknown!
 * 	Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\tmutation SettingsUpdate($settings: UserSettingsUpdateInput!) {\n\t\t\tselfUpdate(input: { data: { settings: $settings } }) {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t",
): (typeof documents)["\n\t\tmutation SettingsUpdate($settings: UserSettingsUpdateInput!) {\n\t\t\tselfUpdate(input: { data: { settings: $settings } }) {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\tquery HeaderProfile {\n\t\t\tme {\n\t\t\t\tid\n\t\t\t\tgivenName\n\t\t\t\tsurname\n\t\t\t\temail\n\t\t\t}\n\t\t}\n\t",
): (typeof documents)["\n\t\tquery HeaderProfile {\n\t\t\tme {\n\t\t\t\tid\n\t\t\t\tgivenName\n\t\t\t\tsurname\n\t\t\t\temail\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\tquery PrivacyStatementAlert($service: String!) {\n\t\t\tactivePrivacyStatement(service: $service) {\n\t\t\t\tid\n\t\t\t\tpublishedAt\n\t\t\t\tversion\n\t\t\t\tcontent\n\t\t\t}\n\t\t}\n\t",
): (typeof documents)["\n\t\tquery PrivacyStatementAlert($service: String!) {\n\t\t\tactivePrivacyStatement(service: $service) {\n\t\t\t\tid\n\t\t\t\tpublishedAt\n\t\t\t\tversion\n\t\t\t\tcontent\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\t\tquery CNMIMCalculateSodium($formulas: [CNMIMSodiumFormulaInput!]!) {\n\t\t\t\tCNMIMCalculateSodium(formulas: $formulas) {\n\t\t\t\t\tformulationId\n\t\t\t\t\tsodium\n\t\t\t\t}\n\t\t\t}\n\t\t",
): (typeof documents)["\n\t\t\tquery CNMIMCalculateSodium($formulas: [CNMIMSodiumFormulaInput!]!) {\n\t\t\t\tCNMIMCalculateSodium(formulas: $formulas) {\n\t\t\t\t\tformulationId\n\t\t\t\t\tsodium\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\t\tmutation LogActivity($input: ActivityCreateInput!) {\n\t\t\t\tlogActivity(input: $input)\n\t\t\t}\n\t\t",
): (typeof documents)["\n\t\t\tmutation LogActivity($input: ActivityCreateInput!) {\n\t\t\t\tlogActivity(input: $input)\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\tquery SolveCalculation(\n\t\t$formulas: [CNMIMMoldGrowthFormulaInput!]!\n\t\t$reference: CNMIMMoldGrowthReferenceFormulaInput!\n\t\t$regionHandle: String!\n\t\t$productName: String!\n\t\t$additionalInformation: String\n\t\t$foodType: String\n\t) {\n\t\tCNMIMCalculateMoldGrowth(\n\t\t\tformulas: $formulas\n\t\t\treference: $reference\n\t\t\tregionHandle: $regionHandle\n\t\t\tproductName: $productName\n\t\t\tadditionalInformation: $additionalInformation\n\t\t\tfoodType: $foodType\n\t\t) {\n\t\t\tid\n\n\t\t\tmoldGroupResults {\n\t\t\t\ttype\n\t\t\t\tminPredictedDays\n\t\t\t\twarning\n\t\t\t\tmoldGrowthFormulations {\n\t\t\t\t\tid\n\t\t\t\t\tisolatedFrom\n\t\t\t\t\tpredictedDays\n\t\t\t\t\tname\n\t\t\t\t\tstrainCode\n\t\t\t\t\twarning\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n",
): (typeof documents)["\n\tquery SolveCalculation(\n\t\t$formulas: [CNMIMMoldGrowthFormulaInput!]!\n\t\t$reference: CNMIMMoldGrowthReferenceFormulaInput!\n\t\t$regionHandle: String!\n\t\t$productName: String!\n\t\t$additionalInformation: String\n\t\t$foodType: String\n\t) {\n\t\tCNMIMCalculateMoldGrowth(\n\t\t\tformulas: $formulas\n\t\t\treference: $reference\n\t\t\tregionHandle: $regionHandle\n\t\t\tproductName: $productName\n\t\t\tadditionalInformation: $additionalInformation\n\t\t\tfoodType: $foodType\n\t\t) {\n\t\t\tid\n\n\t\t\tmoldGroupResults {\n\t\t\t\ttype\n\t\t\t\tminPredictedDays\n\t\t\t\twarning\n\t\t\t\tmoldGrowthFormulations {\n\t\t\t\t\tid\n\t\t\t\t\tisolatedFrom\n\t\t\t\t\tpredictedDays\n\t\t\t\t\tname\n\t\t\t\t\tstrainCode\n\t\t\t\t\twarning\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\t\t\tquery PermissionsHaveChanged {\n\t\t\t\t\tmyPermissionsHaveChanged\n\t\t\t\t}\n\t\t\t",
): (typeof documents)["\n\t\t\t\tquery PermissionsHaveChanged {\n\t\t\t\t\tmyPermissionsHaveChanged\n\t\t\t\t}\n\t\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\t\t\tquery PrivacyStatementHasAccepted {\n\t\t\t\t\thasAcceptedPrivacyStatement\n\t\t\t\t}\n\t\t\t",
): (typeof documents)["\n\t\t\t\tquery PrivacyStatementHasAccepted {\n\t\t\t\t\thasAcceptedPrivacyStatement\n\t\t\t\t}\n\t\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\t\t\tmutation AcceptPrivacyStatement {\n\t\t\t\t\tacceptPrivacyStatement\n\t\t\t\t}\n\t\t\t",
): (typeof documents)["\n\t\t\t\tmutation AcceptPrivacyStatement {\n\t\t\t\t\tacceptPrivacyStatement\n\t\t\t\t}\n\t\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\t\tquery PortfolioProducts($regionHandle: String!) {\n\t\t\t\tCNMIMPortfolio(input: { regionAvailable: { handle: $regionHandle } }) {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t",
): (typeof documents)["\n\t\t\tquery PortfolioProducts($regionHandle: String!) {\n\t\t\t\tCNMIMPortfolio(input: { regionAvailable: { handle: $regionHandle } }) {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\t\t\tquery Settings {\n\t\t\t\t\tme {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tsettings {\n\t\t\t\t\t\t\tdefaultUnitOfWeight\n\t\t\t\t\t\t\tservingSizeInGrams\n\t\t\t\t\t\t\tcnmimSettings\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t",
): (typeof documents)["\n\t\t\t\tquery Settings {\n\t\t\t\t\tme {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tsettings {\n\t\t\t\t\t\t\tdefaultUnitOfWeight\n\t\t\t\t\t\t\tservingSizeInGrams\n\t\t\t\t\t\t\tcnmimSettings\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\tquery CNMIMReferenceFormulaDefaultDaysToVisualMoldGrowth {\n\t\t\tCNMIMReferenceFormulaDefaultDaysToVisualMoldGrowth\n\t\t}\n\t",
): (typeof documents)["\n\t\tquery CNMIMReferenceFormulaDefaultDaysToVisualMoldGrowth {\n\t\t\tCNMIMReferenceFormulaDefaultDaysToVisualMoldGrowth\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\tquery ConfigureRegions {\n\t\t\tregions {\n\t\t\t\tid\n\t\t\t\thandle\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t",
): (typeof documents)["\n\t\tquery ConfigureRegions {\n\t\t\tregions {\n\t\t\t\tid\n\t\t\t\thandle\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\tquery ActivePrivacyStatement($service: String!) {\n\t\t\tactivePrivacyStatement(service: $service) {\n\t\t\t\tid\n\t\t\t\tpublishedAt\n\t\t\t\tversion\n\t\t\t\tcontent\n\t\t\t}\n\t\t}\n\t",
): (typeof documents)["\n\t\tquery ActivePrivacyStatement($service: String!) {\n\t\t\tactivePrivacyStatement(service: $service) {\n\t\t\t\tid\n\t\t\t\tpublishedAt\n\t\t\t\tversion\n\t\t\t\tcontent\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\tquery ResultProfile {\n\t\t\tme {\n\t\t\t\tid\n\t\t\t\tgivenName\n\t\t\t\tsurname\n\t\t\t}\n\t\t}\n\t",
): (typeof documents)["\n\t\tquery ResultProfile {\n\t\t\tme {\n\t\t\t\tid\n\t\t\t\tgivenName\n\t\t\t\tsurname\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL
 * clients.
 */
export function graphql(
	source: "\n\t\tquery PortfolioList {\n\t\t\tCNMIMPortfolio {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t",
): (typeof documents)["\n\t\tquery PortfolioList {\n\t\t\tCNMIMPortfolio {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t"];

export function graphql(source: string) {
	return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
	TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
