import { ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useIsAuthenticated } from "@stijlbreuk/msal-vue";
import { defineStore } from "pinia";
import { z } from "zod";
import { graphql } from "@/graphql";
import { UnitOfWeightSchema } from "@/graphql/validation";

export const SettingsSchema = z.object({
	defaultUnitOfWeight: UnitOfWeightSchema.default("GRAMS"),
	servingSizeInGrams: z.coerce.number().default(57),
	cnmimSettings: z
		.object({
			showExcel: z.coerce.boolean().default(true),
			showPrototypes: z.coerce.boolean().default(true),
			showPureProducts: z.coerce.boolean().default(true),
			showSodiumContent: z.coerce.boolean().default(true),
		})
		.passthrough()
		.default({}),
});

export const useSettingsStore = defineStore(
	"settings",
	() => {
		const isAuthenticated = useIsAuthenticated();

		const defaultUnitOfWeight = ref(SettingsSchema.shape.defaultUnitOfWeight.parse(undefined));
		const servingSizeInGrams = ref(SettingsSchema.shape.servingSizeInGrams.parse(undefined));
		const cnmimSettings = ref(SettingsSchema.shape.cnmimSettings.parse(undefined));

		useQuery(
			graphql(`
				query Settings {
					me {
						id
						settings {
							defaultUnitOfWeight
							servingSizeInGrams
							cnmimSettings
						}
					}
				}
			`),
			null,
			{ enabled: isAuthenticated.value },
		).onResult((value) => {
			if (!value?.data) {
				return;
			}

			const settings = value.data.me.settings;

			defaultUnitOfWeight.value = SettingsSchema.shape.defaultUnitOfWeight.parse(
				settings.defaultUnitOfWeight,
			);
			servingSizeInGrams.value = SettingsSchema.shape.servingSizeInGrams.parse(
				settings.servingSizeInGrams,
			);
			cnmimSettings.value = SettingsSchema.shape.cnmimSettings.parse(settings.cnmimSettings || {});
		});

		return { defaultUnitOfWeight, servingSizeInGrams, cnmimSettings };
	},
	{
		persist: {
			serializer: {
				deserialize: (value) => SettingsSchema.parse(JSON.parse(value)),
				serialize: (value) => JSON.stringify(value),
			},
		},
	},
);
