import { computed, ref, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useIsAuthenticated } from "@stijlbreuk/msal-vue";
import { useWindowFocus } from "@vueuse/core";
import { useJwt } from "@vueuse/integrations/useJwt";
import { addDays, isPast, minutesToMilliseconds } from "date-fns";
import type { JwtPayload } from "jwt-decode";
import { defineStore, storeToRefs } from "pinia";
import { toast } from "vue-sonner";
import { z } from "zod";
import { graphql } from "@/graphql";
import { msalInstance } from "@/lib/services/msal/instance";
import { msalRequest } from "@/lib/services/msal/request";
import { useAuthStore } from "@/store/auth";

const KnownPermissionSchema = z.discriminatedUnion("handle", [
	z.object({
		handle: z.literal("cnmim.formulation.compact_mode"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.formulation.current_days_to_mold"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.formulation.detailed_results"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.formulation.export_excel"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.formulation.mold_results"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.formulation.print_pdf"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.formulation.reference.visual_mold_growth.update"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.formulation.show_individual_molds"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.formulation.solve"),
		value: z.coerce.number().default(0),
	}),
	z.object({
		handle: z.literal("cnmim.products.read:antimicrobials"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.products.read:corbion"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.products.read:prototype"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("cnmim.products.read:pure"),
		value: z.coerce.boolean().default(false),
	}),
	z.object({
		handle: z.literal("sodium.calculate"),
		value: z.coerce.boolean().default(false),
	}),
]);

const UnknownPermissionSchema = z.object({ handle: z.string() }).passthrough();

const PermissionSchema = z.union([KnownPermissionSchema, UnknownPermissionSchema]);

type Permission = z.infer<typeof KnownPermissionSchema>;
type PermissionHandle = Permission["handle"];

export const usePermissionsStore = defineStore(
	"permissions",
	() => {
		const isWindowFocused = useWindowFocus();
		const isAuthenticated = useIsAuthenticated();
		const alertDismissed = ref<Date>();
		const { token } = storeToRefs(useAuthStore());
		const accessToken = computed(() => token.value?.accessToken ?? "");
		const { payload } = useJwt<JwtPayload & { extension_Permissions: string }>(accessToken);

		const permissions = computed(() =>
			z
				.array(PermissionSchema)
				.default([])
				.parse(JSON.parse(payload.value?.extension_Permissions ?? "[]")),
		);

		const loaded = computed(() => permissions.value.length > 0 || !!token.value?.accessToken);

		const haveChangedQuery = useQuery(
			graphql(`
				query PermissionsHaveChanged {
					myPermissionsHaveChanged
				}
			`),
			null,
			{
				fetchPolicy: "cache-and-network",
				notifyOnNetworkStatusChange: true,
				enabled: isAuthenticated.value && isWindowFocused.value,
			},
		);
		const haveChanged = computed(
			() => haveChangedQuery.result.value?.myPermissionsHaveChanged ?? false,
		);

		watch(
			haveChanged,
			(value) => {
				if (!value || (alertDismissed.value && !isPast(alertDismissed.value))) {
					return;
				}

				toast.info("Your permissions have changed!", {
					id: "permissions-have-changed",
					description: "Re-authentication is required",
					onDismiss: () => (alertDismissed.value = addDays(new Date(), 1)),
					action: {
						label: "Update",
						onClick: async () =>
							await msalInstance.logoutRedirect({
								...msalRequest,
								postLogoutRedirectUri: "/redirect/sign-in",
							}),
					},
					duration: minutesToMilliseconds(30),
					important: true,
				});
			},
			{ immediate: true },
		);

		function getValue<T extends PermissionHandle>(handle: T) {
			return permissions.value.find((permission) => permission.handle === handle)?.value as Extract<
				Permission,
				{ handle: T }
			>["value"];
		}

		return { loaded, haveChanged, permissions, getValue };
	},
	{
		persist: false,
	},
);
