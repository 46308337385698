import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { z } from "zod";

export const TourSchema = z.object({
	started: z.coerce.boolean().default(false),
	completed: z.coerce.boolean().default(false),
	active: z.coerce.boolean().default(false),
});

export const ToursSchema = z.object({
	onboarding: TourSchema.default(TourSchema.parse({})),
});

export const useToursStore = defineStore(
	"tours",
	() => {
		const onboarding = ref(ToursSchema.shape.onboarding.parse(undefined));

		const active = computed(
			() =>
				[["onboarding", onboarding.value.active]].filter(([, active]) => active)?.[0]?.[0] as
					| keyof z.infer<typeof ToursSchema>
					| undefined,
		);

		return { active, onboarding };
	},
	{
		persist: {
			omit: ["onboarding.active"],
			serializer: {
				deserialize: (value) => ToursSchema.parse(JSON.parse(value)),
				serialize: (value) => JSON.stringify(value),
			},
		},
	},
);
