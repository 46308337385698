<template>
	<Dialog v-model:open="dialogOpen">
		<DialogTrigger as-child>
			<Button variant="naked" class="gap-2 text-base" v-bind="$attrs">
				<Settings class="size-4" />
				Settings
			</Button>
		</DialogTrigger>
		<DialogContent>
			<DialogHeader>
				<DialogTitle class="text-2xl">Settings</DialogTitle>
				<DialogDescription>
					Make changes to your settings for your Corbion account here.
				</DialogDescription>
			</DialogHeader>

			<div class="flex flex-col gap-4">
				<div class="flex flex-col">
					<Text as="h3" variant="heading" class="text-xl">Defaults</Text>

					<Text variant="muted" class="text-sm">
						These defaults will be used in all supported calculators.
					</Text>
				</div>

				<div class="flex flex-col gap-2">
					<Label for="defaultUnitOfWeight" class="flex justify-between gap-2">
						Default unit of weight
						<LoadingIcon v-if="loading === 'defaultUnitOfWeight'" class="size-3" />
					</Label>

					<div class="flex items-center justify-between gap-2">
						<Select
							id="defaultUnitOfWeight"
							:model-value="defaultUnitOfWeight"
							@update:model-value="
								updateSettings('defaultUnitOfWeight', {
									defaultUnitOfWeight: $event as UnitOfWeight,
								})
							"
						>
							<SelectTrigger>
								<SelectValue placeholder="Select a default unit of weight" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									<SelectItem
										v-for="[key, value] in Object.entries(UNIT_OF_WEIGHT)"
										:key="key"
										:value="key"
									>
										{{ value.label }} ({{ value.unit }})
									</SelectItem>
								</SelectGroup>
							</SelectContent>
						</Select>

						<div class="min-w-24" />
					</div>
				</div>

				<div v-if="permissionsStore.getValue('sodium.calculate')" class="flex flex-col gap-2">
					<Label for="servingSizeInGrams" class="flex justify-between gap-2">
						Default serving size ({{ UNIT_OF_WEIGHT[defaultUnitOfWeight].label }})
						<LoadingIcon v-if="loading === 'servingSizeInGrams'" class="size-3" />
					</Label>

					<div class="flex items-center justify-between gap-2">
						<Input
							type="number"
							:min="0"
							:model-value="
								convertWeight({
									from: 'GRAMS',
									to: defaultUnitOfWeight,
									amount: servingSizeInGrams,
								})
							"
							@update:model-value="debouncedServingSizeInGramsUpdate(Number($event))"
						/>

						<div class="grid min-w-24 place-items-center">
							<Text
								v-if="defaultUnitOfWeight !== 'GRAMS'"
								variant="muted"
								class="text-xs tabular-nums"
							>
								{{
									servingSizeInGrams.toLocaleString("default", {
										style: "unit",
										unit: "gram",
									})
								}}
							</Text>
						</div>
					</div>
				</div>
			</div>

			<div v-if="demoToggles.some((toggle) => toggle.visible)" class="flex flex-col gap-3">
				<div class="flex flex-col">
					<div class="flex items-center justify-between">
						<Text as="h3" variant="heading" class="text-xl">Demo settings</Text>
						<LoadingIcon v-if="loading === 'demo'" class="size-5" />
					</div>

					<Text variant="muted" class="text-sm">
						These settings will change how CNMIM is displayed.
					</Text>
				</div>

				<div class="flex flex-col gap-2">
					<template v-for="toggle in demoToggles">
						<div
							v-if="toggle.visible"
							:key="toggle.key"
							class="flex items-center justify-between gap-2"
						>
							<Label :for="toggle.key">{{ toggle.label }}</Label>
							<Switch :id="toggle.key" v-model:checked="cnmimSettings[toggle.key]" />
						</div>
					</template>
				</div>
			</div>
		</DialogContent>
	</Dialog>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ref, watch } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { debounce } from "lodash";
import { Settings } from "lucide-vue-next";
import { storeToRefs } from "pinia";
import { toast } from "vue-sonner";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { Button } from "@/components/ui/button";
import Text from "@/components/ui/custom/Text.vue";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { graphql } from "@/graphql";
import type { UnitOfWeight, UserSettingsUpdateInput } from "@/graphql/graphql";
import { UNIT_OF_WEIGHT } from "@/lib/constants/UnitOfWeight";
import { usePermissionsStore } from "@/store/permissions";
import { useSettingsStore } from "@/store/settings";
import { convertWeight } from "@/utils";

const props = withDefaults(
	defineProps<{
		/** The controlled open state of the dialog. Can be binded as `v-model:open`. */ open?: boolean;
	}>(),
	{
		open: undefined,
	},
);
const emits = defineEmits<{ "update:open": [value: boolean] }>();

const internalOpen = ref<boolean>(props.open ?? false);
const dialogOpen = computed({
	get: () => props.open ?? internalOpen.value,
	set: (newValue) => {
		emits("update:open", newValue ?? false);
		internalOpen.value = newValue;
	},
});

const mutation = useMutation(
	graphql(`
		mutation SettingsUpdate($settings: UserSettingsUpdateInput!) {
			selfUpdate(input: { data: { settings: $settings } }) {
				id
			}
		}
	`),
	{
		awaitRefetchQueries: true,
		refetchQueries: ["Settings"],
	},
);

const permissionsStore = usePermissionsStore();
const settingsStore = useSettingsStore();
const { defaultUnitOfWeight, servingSizeInGrams } = storeToRefs(settingsStore);
const cnmimSettings = ref(settingsStore.cnmimSettings);

type Key = "defaultUnitOfWeight" | "servingSizeInGrams" | "demo";

const loading = ref<Key | null>(null);

const demoToggles = computed(
	() =>
		[
			{
				key: "showExcel",
				label: "Show Excel",
				visible: permissionsStore.getValue("cnmim.formulation.export_excel"),
			},
			{
				key: "showPrototypes",
				label: "Show Prototypes",
				visible: permissionsStore.getValue("cnmim.products.read:prototype"),
			},
			{
				key: "showPureProducts",
				label: "Show Pure Products",
				visible: permissionsStore.getValue("cnmim.products.read:pure"),
			},
			{
				key: "showSodiumContent",
				label: "Show Sodium Content",
				visible: permissionsStore.getValue("sodium.calculate"),
			},
		] as const satisfies ReadonlyArray<{
			key: keyof typeof cnmimSettings.value;
			label: string;
			visible: boolean;
		}>,
);

const debouncedServingSizeInGramsUpdate = debounce((value: number) => {
	updateSettings("servingSizeInGrams", {
		servingSizeInGrams: convertWeight({
			from: defaultUnitOfWeight.value,
			to: "GRAMS",
			amount: value,
		}),
	});
}, 500);

async function updateSettings(key: Key, settings: UserSettingsUpdateInput) {
	loading.value = key;

	const TOAST_ID = "settings-update";

	toast.loading("Updating settings...", { id: TOAST_ID });

	const result = await mutation.mutate({ settings });

	if (result?.data) {
		const item = (function () {
			switch (key) {
				case "defaultUnitOfWeight":
					return "default unit of weight";
				case "servingSizeInGrams":
					return "serving size";
				case "demo":
					return "demo settings";
				default:
					return "setting";
			}
		})();

		toast.success(`Updated ${item}`, { id: TOAST_ID });
	}

	result?.errors?.forEach((error) => {
		switch (error) {
			default:
				toast.error(error || "Unknown error", { id: TOAST_ID });
				break;
		}
	});

	loading.value = null;
}

watch(cnmimSettings, (value) => updateSettings("demo", { cnmimSettings: value }), { deep: true });
</script>
