<template>
	<button
		class="sb_button"
		v-bind="$attrs"
		:class="{
			'sb_button--primary': buttonStyle === 'primary',
			'sb_button--outline': buttonStyle === 'outline',
			'sb_button--ghost': buttonStyle === 'ghost',
			'sb_button--naked': buttonStyle === 'naked',
			'sb_button--icon': buttonStyle === 'icon',
			'sb_button--small': buttonStyle === 'small',
		}"
		@click="emits('click', $event)"
	>
		<span v-if="!!$slots['before']" class="sb_button_before">
			<slot name="before" />
		</span>

		<span v-if="!!$slots['default']" class="sb_button_text">
			<slot />
		</span>

		<span v-if="!!$slots['after']" class="sb_button_after">
			<slot name="after" />
		</span>
	</button>
</template>

<script setup lang="ts">
withDefaults(
	defineProps<{
		buttonStyle?: "primary" | "outline" | "ghost" | "naked" | "icon" | "small";
	}>(),
	{
		buttonStyle: "primary",
	},
);

const emits = defineEmits<{ (e: "click", value: MouseEvent): void }>();
</script>

<style lang="scss">
.sb_button,
button.sb_button {
	--button-size: 40px;
	--accent-color: #{color.channel(color.to-space($brand-primary-lighter, rgb), "red"),
		color.channel(color.to-space($brand-primary-lighter, rgb), "green"),
		color.channel(color.to-space($brand-primary-lighter, rgb), "blue")};
	--color: #{color.channel(color.to-space($brand-white, rgb), "red"),
		color.channel(color.to-space($brand-white, rgb), "green"),
		color.channel(color.to-space($brand-white, rgb), "blue")};
	--disabled-color: #{color.channel(color.to-space($brand-gray, rgb), "red"),
		color.channel(color.to-space($brand-gray, rgb), "green"),
		color.channel(color.to-space($brand-gray, rgb), "blue")};
	--ring-color-inner: #{$brand-white};
	--ring-color-outer: #{$brand-primary-darker};
	--ring-size-inner: #{$border-width * 2};
	--ring-size: calc(var(--ring-size-inner) * 2);

	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: $padding * 0.5;
	height: var(--button-size);
	width: auto;
	border-radius: $border-radius;
	cursor: pointer;
	padding: 0 calc(var(--button-size) * 0.25);
	background: rgb(var(--accent-color));
	border-color: rgb(var(--accent-color));
	border-style: $border-style;
	border-width: $border-width * 2;
	color: rgb(var(--color));
	transition-property: background, border-color, box-shadow;
	transition-timing-function: $transition-timing-function;
	transition-duration: $transition-duration-short;

	&_before,
	&_text,
	&_after {
		pointer-events: none;
	}

	&_before,
	&_after {
		color: rgba(var(--color), 0.5);
	}

	&:hover {
		background: rgba(var(--accent-color), 0.9);
		border-color: rgba(var(--accent-color), 0.9);
	}

	&:active {
		background: rgba(var(--accent-color), 0.8);
		border-color: rgba(var(--accent-color), 0.8);
	}

	&:focus {
		outline: none;
		box-shadow:
			var(--ring-color-inner) 0 0 0 var(--ring-size-inner),
			var(--ring-color, rgb(var(--accent-color))) 0 0 0 var(--ring-size),
			var(--ring-color-outer) 0 0 0 0;
	}

	&:disabled {
		background: rgb(var(--disabled-color));
		border-color: rgb(var(--disabled-color));
		cursor: not-allowed;
	}

	&--outline {
		background: none;
		color: rgb(var(--accent-color));
		border-color: var(--accent-color);
		border-style: $border-style;
		border-width: $border-width * 2;
		transition-property: background, box-shadow, border-color, color;

		.sb_button_before,
		.sb_button_after {
			color: inherit;
		}

		&:hover {
			background: rgba(var(--accent-color), 0.1);
		}

		&:active {
			background: rgba(var(--accent-color), 0.2);
		}

		&:focus {
			box-shadow:
				var(--ring-color-inner) 0 0 0 var(--ring-size-inner),
				rgb(var(--accent-color)) 0 0 0 var(--ring-size),
				var(--ring-color-outer) 0 0 0 0;
		}

		&:disabled {
			background: rgb(var(--disabled-color), 0.1);
			border-color: rgb(var(--disabled-color));
			color: rgb(var(--disabled-color));
			cursor: not-allowed;
		}
	}

	&--ghost {
		background: rgba(var(--accent-color), 0.1);
		color: rgb(var(--accent-color));
		transition-property: background, box-shadow, border-color, color;
		border-color: transparent;

		.sb_button_before,
		.sb_button_after {
			color: inherit;
		}

		&:hover {
			background: rgba(var(--accent-color), 0.15);
			border-color: transparent;
		}

		&:active {
			background: rgba(var(--accent-color), 0.2);
			border-color: transparent;
		}

		&:focus {
			box-shadow:
				var(--ring-color-inner) 0 0 0 var(--ring-size-inner),
				rgb(var(--accent-color)) 0 0 0 var(--ring-size),
				var(--ring-color-outer) 0 0 0 0;
			border-color: transparent;
		}

		&:disabled {
			background: rgb(var(--disabled-color), 0.1);
			border-color: transparent;
			color: rgb(var(--disabled-color));
			cursor: not-allowed;
		}
	}

	&--naked {
		--accent-color: #{color.channel(color.to-space($brand-primary, rgb), "red"),
			color.channel(color.to-space($brand-primary, rgb), "green"),
			color.channel(color.to-space($brand-primary, rgb), "blue")};

		background: none;
		border-color: transparent;
		color: rgb(var(--accent-color));
		transition-property: background, box-shadow, border-color, color;

		.sb_button_before,
		.sb_button_after {
			color: inherit;
		}

		&:hover {
			background: rgba(var(--accent-color), 0.1);
			border-color: transparent;
		}

		&:active {
			background: rgba(var(--accent-color), 0.2);
			border-color: transparent;
		}

		&:focus {
			box-shadow:
				var(--ring-color-inner) 0 0 0 var(--ring-size-inner),
				rgb(var(--accent-color)) 0 0 0 var(--ring-size),
				var(--ring-color-outer) 0 0 0 0;
		}

		&:disabled {
			background: rgb(var(--disabled-color), 0.1);
			border-color: rgb(var(--disabled-color));
			color: rgb(var(--disabled-color));
			cursor: not-allowed;
		}
	}

	&--icon {
		justify-content: center;
		width: var(--button-size);
		min-width: var(--button-size);
		background: rgba(var(--accent-color), var(--background-opacity, 0));
		color: rgb(var(--accent-color));
		border-color: transparent;

		.sb_button_text {
			margin-block-start: unset;

			> * {
				--icon-size: var(--button-icon-size, calc(var(--button-size) * 0.6));
			}
		}

		&:hover {
			background: rgba(var(--accent-color), var(--background-opacity-hover, 0));
			border-color: transparent;
		}

		&:active {
			background: rgba(var(--accent-color), var(--background-opacity-active, 0));
			border-color: transparent;
		}
	}

	&--small {
		--button-size: 28px;

		font-size: 0.9rem;
		background: rgba(var(--accent-color), 0.1);
		color: rgb(var(--accent-color));
		transition-property: background, box-shadow, border-color, color;
		font-weight: 700;
		border-color: transparent;

		.sb_button_before,
		.sb_button_after {
			color: inherit;
		}

		&:hover {
			background: rgba(var(--accent-color), 0.15);
			border-color: transparent;
		}

		&:active {
			background: rgba(var(--accent-color), 0.2);
			border-color: transparent;
		}

		&:focus {
			box-shadow:
				var(--ring-color-inner) 0 0 0 var(--ring-size-inner),
				rgb(var(--accent-color)) 0 0 0 var(--ring-size),
				var(--ring-color-outer) 0 0 0 0;
		}

		&:disabled {
			background: rgb(var(--disabled-color), 0.1);
			border-color: rgb(var(--disabled-color));
			color: rgb(var(--disabled-color));
			cursor: not-allowed;
		}
	}
}
</style>
