import type { ApolloQueryResult } from "@apollo/client/core/types";
import { cloneDeep } from "lodash";
import { toast } from "vue-sonner";
import { graphql } from "@/graphql";
import type {
	CnmimMoldGrowthFormulaInput,
	CnmimMoldGrowthReferenceFormulaInput,
	SolveCalculationQuery,
} from "@/graphql/graphql";
import { DEFAULT_CONTROL_DAYS } from "@/lib/constants/app";
import { apolloClient } from "@/lib/services/apollo/client";
import { useConfigurationStore } from "@/store/configuration";
import {
	REFERENCE_FORMULATION_ID,
	TEMPORARY_INGREDIENT_ID_PREFIX,
	useFormulationsStore,
} from "@/store/formulations";
import { useMiscStore } from "@/store/misc";
import { usePermissionsStore } from "@/store/permissions";
import { useProductsStore } from "@/store/products";
import { useSettingsStore } from "@/store/settings";
import type { Formulation, YourReferenceFormulation } from "@/types/calculation";
import { convertWeight, isYourReferenceFormulation } from "@/utils";
import { calculation, savedResults } from "./state";

export const SolveFormulationQuery = graphql(`
	query SolveCalculation(
		$formulas: [CNMIMMoldGrowthFormulaInput!]!
		$reference: CNMIMMoldGrowthReferenceFormulaInput!
		$regionHandle: String!
		$productName: String!
		$additionalInformation: String
		$foodType: String
	) {
		CNMIMCalculateMoldGrowth(
			formulas: $formulas
			reference: $reference
			regionHandle: $regionHandle
			productName: $productName
			additionalInformation: $additionalInformation
			foodType: $foodType
		) {
			id

			moldGroupResults {
				type
				minPredictedDays
				warning
				moldGrowthFormulations {
					id
					isolatedFrom
					predictedDays
					name
					strainCode
					warning
				}
			}
		}
	}
`);

export const resetCalculation = async () => {
	useFormulationsStore().$reset();
	useMiscStore().$patch({ resultsClosed: true });

	return { success: true };
};

export const validateCalculation = async () => {
	const formulationsStore = useFormulationsStore();
	const { products } = useProductsStore();
	const { formulations } = formulationsStore;

	calculation.validating.flourWeightMissing = false;
	calculation.validating.totalBatchWeightMissing = false;
	calculation.validating.bakeLossMissing = false;

	if (formulations.length <= 1) {
		toast.error("There are no formulations to calculate");

		return { succes: false };
	}

	const control =
		formulations.find((formulation) => formulation.id === REFERENCE_FORMULATION_ID) ??
		formulations[0];

	if (isYourReferenceFormulation(control)) {
		let isValid = true;

		if (!control.bakersInput.flourWeight) {
			calculation.validating.flourWeightMissing = true;
			isValid = false;
		}

		if (!control.bakersInput.totalBatchWeight) {
			calculation.validating.totalBatchWeightMissing = true;
			isValid = false;
		}

		if (!control.bakersInput.bakeLoss) {
			calculation.validating.bakeLossMissing = true;
			isValid = false;
		}

		if (!isValid) {
			formulationsStore.$patch({ selected: REFERENCE_FORMULATION_ID });
			return { success: false };
		}
	}

	let hasInvalidIngredientId = false;
	let hasMissingIngredient = false;

	formulations.forEach((formulation) => {
		if (!hasInvalidIngredientId) {
			hasInvalidIngredientId = !formulation.ingredients.every(
				(ingredient) => products.findIndex((product) => product.id === ingredient.id) !== -1,
			);
		}

		const emptyIngredient = formulation.ingredients.find((ingredient) => ingredient.id === "");

		if (emptyIngredient) {
			hasMissingIngredient = true;
		}
	});

	if (hasInvalidIngredientId) {
		toast.error("Some ingredients are invalid or empty");
		return { success: false };
	}

	if (hasMissingIngredient) {
		toast.error("Some ingredients are missing a value");
		return { success: false };
	}

	return { success: true };
};

export const solveCalculation = async () => {
	const { defaultUnitOfWeight } = useSettingsStore();

	const { region, foodType, additionalInformation, productName, foodTypeDefault } =
		useConfigurationStore();
	const miscStore = useMiscStore();
	const formulationsStore = useFormulationsStore();

	miscStore.$patch({ resultsClosed: false });

	calculation.calculating = true;
	calculation.id = undefined;
	calculation.result.formulationNames = [];
	calculation.result.formulationResult = [];
	calculation.validating.flourWeightMissing = false;
	calculation.validating.totalBatchWeightMissing = false;
	calculation.validating.bakeLossMissing = false;

	const formulations = cloneDeep(formulationsStore.calculationFormulations);
	const referenceFormulation = formulations.find(
		(formulation): formulation is YourReferenceFormulation =>
			formulation.id === REFERENCE_FORMULATION_ID,
	);

	const transformedReferenceFormulation: CnmimMoldGrowthReferenceFormulaInput = {
		id: referenceFormulation?.id ?? "",
		name: referenceFormulation?.name ?? "",
		...(foodTypeDefault.showFormulationParameters
			? {
					bakersInput: {
						flourWeight: convertWeight({
							from: defaultUnitOfWeight,
							to: "GRAMS",
							amount: referenceFormulation?.bakersInput.flourWeight ?? 1200,
						}),
						totalBatchWeight: convertWeight({
							from: defaultUnitOfWeight,
							to: "GRAMS",
							amount: referenceFormulation?.bakersInput.totalBatchWeight ?? 2250,
						}),
						bakeLoss: referenceFormulation?.bakersInput.bakeLoss ?? 13,
					},
				}
			: {}),
		...(usePermissionsStore().getValue("cnmim.formulation.reference.visual_mold_growth.update")
			? {
					daysToVisualMoldGrowth:
						referenceFormulation?.daysToVisualMoldGrowth ?? DEFAULT_CONTROL_DAYS,
				}
			: {}),
		moisture: referenceFormulation?.moisture ?? foodTypeDefault.moisture.default,
		pH: referenceFormulation?.pH ?? foodTypeDefault.pH.default,
		aW: referenceFormulation?.aW ?? foodTypeDefault.aW.default,
		ingredients:
			referenceFormulation?.ingredients?.filter(
				(ingredient) =>
					!ingredient.id.startsWith(TEMPORARY_INGREDIENT_ID_PREFIX) &&
					ingredient.weightForWeight !== null &&
					ingredient.weightForWeight !== 0,
			) ?? [],
	};

	const otherFormulations = formulations.filter(({ id }) => id !== REFERENCE_FORMULATION_ID);

	const transformedOtherFormulations = otherFormulations.map<CnmimMoldGrowthFormulaInput>(
		(formulation) => ({
			id: formulation.id,
			name: formulation.name,
			...(foodTypeDefault.showFormulationParameters
				? {
						bakersInput: {
							flourWeight: convertWeight({
								from: defaultUnitOfWeight,
								to: "GRAMS",
								amount:
									formulation.bakersInput.flourWeight ??
									referenceFormulation?.bakersInput.flourWeight ??
									1200,
							}),
							totalBatchWeight: convertWeight({
								from: defaultUnitOfWeight,
								to: "GRAMS",
								amount:
									formulation.bakersInput.totalBatchWeight ??
									referenceFormulation?.bakersInput.totalBatchWeight ??
									2250,
							}),
							bakeLoss:
								formulation.bakersInput.bakeLoss ??
								referenceFormulation?.bakersInput.bakeLoss ??
								13,
						},
					}
				: {}),
			moisture:
				formulation?.moisture ?? referenceFormulation?.moisture ?? foodTypeDefault.moisture.default,
			pH: formulation?.pH ?? referenceFormulation?.pH ?? foodTypeDefault.pH.default,
			aW: formulation?.aW ?? referenceFormulation?.aW ?? foodTypeDefault.aW.default,
			ingredients: formulation.ingredients
				.filter((ingredient) => ingredient.id !== undefined)
				.map((ingredient) => ({
					id: ingredient.id,
					weightForWeight:
						ingredient.weightForWeight ??
						referenceFormulation?.ingredients.find(({ id }) => id === ingredient.id)
							?.weightForWeight,
				})),
		}),
	);

	calculation.result.formulationNames = otherFormulations.map(({ name }) => name);

	// Use Promise.race to set a timeout for the GraphQL query

	try {
		const timeout = new Promise((_, reject) =>
			setTimeout(() => {
				if (!resolved) {
					toast.error("Request timed out", {
						description: "The request took too long to complete",
					});
					reject(new Error("Timeout"));
				}
			}, 15000),
		);

		let resolved = false;

		const myQuery = apolloClient.query({
			query: SolveFormulationQuery,
			variables: {
				formulas: transformedOtherFormulations,
				reference: transformedReferenceFormulation,
				regionHandle: region ?? "",
				productName: productName ?? "",
				foodType: foodType,
				additionalInformation: additionalInformation,
			},
			fetchPolicy: "no-cache",
		});

		const { data }: ApolloQueryResult<SolveCalculationQuery> = (await Promise.race([
			myQuery.then((result) => {
				resolved = true;
				return result;
			}),
			timeout,
		])) as ApolloQueryResult<SolveCalculationQuery>;

		calculation.result.formulationResult = data.CNMIMCalculateMoldGrowth;

		const newId = crypto.randomUUID();

		savedResults.value = [
			...savedResults.value,
			{
				id: newId,
				region,
				foodType,
				productName,
				additionalInformation,
				formulations: [
					{
						...transformedReferenceFormulation,
						name: referenceFormulation?.name,
					} as unknown as Formulation,
					...(transformedOtherFormulations.map((formulation, index) => ({
						...formulation,
						name: otherFormulations[index].name,
					})) as Formulation[]),
				],
				unitUsed: defaultUnitOfWeight,
				results: data.CNMIMCalculateMoldGrowth,
			},
		];

		calculation.currentResult = newId;

		calculation.calculating = false;

		return { success: true };
	} catch {
		// Reset state.
		calculation.calculating = false;
		calculation.id = undefined;
		calculation.result.formulationNames = [];
		calculation.result.formulationResult = [];
		calculation.validating.flourWeightMissing = false;
		calculation.validating.totalBatchWeightMissing = false;
		calculation.validating.bakeLossMissing = false;
		miscStore.$patch({ resultsClosed: true });
	}
};

export const addFormulation = async (singleOnly = false) => {
	const formulationsStore = useFormulationsStore();
	const { formulations, syncProducts } = formulationsStore;

	// @ts-expect-error idName is not defined
	const lastIdName = formulations[formulations.length - 1].idName;
	let newIdName = "1";

	if (lastIdName !== REFERENCE_FORMULATION_ID && lastIdName !== undefined) {
		newIdName = String(parseInt(lastIdName) + 1);
	}

	const newId = crypto.randomUUID();

	const object: Formulation = {
		...cloneDeep(formulations[0]),
		id: newId,
		// @ts-expect-error Not defined
		idName: newIdName,
		name: `Formulation ${newIdName}`,
		ingredients: [],
	};

	if (singleOnly !== true || (singleOnly === true && formulations.length === 1)) {
		formulationsStore.$patch((state) => {
			state.selected = newId;
			state.formulations.push(object);
		});
	}

	syncProducts();
	return { success: true };
};
