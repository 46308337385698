import type { Configuration } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
import { IS_PRODUCTION } from "@/lib/constants/env";
import { msalOptions as options } from "@/lib/services/msal/options";

export const msalConfig: Configuration = {
	auth: {
		clientId: options.clientId ?? "",
		authority: [options.domain, "/", options.loginAuthority].join(""),
		knownAuthorities: [options.knownAuthority].filter(Boolean),
		redirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
		postLogoutRedirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
	},
	cache: {
		cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: true, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
	},
	system: {
		allowNativeBroker: false, // Disables WAM Broker
		loggerOptions: {
			loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
				if (containsPii) {
					return;
				}

				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
				}
			},
			piiLoggingEnabled: false,
			logLevel: IS_PRODUCTION ? LogLevel.Info : LogLevel.Verbose,
		},
	},
};
