import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

export const clientEnv = createEnv({
	/**
	 * The prefix that client-side variables must have. This is enforced both at a type-level and at
	 * runtime.
	 */
	clientPrefix: "VITE_",

	/** Variables from Vite https://vitejs.dev/guide/env-and-mode#env-variables */
	shared: {
		MODE: z.union([z.enum(["development", "production"]), z.string()]),
		BASE_URL: z.union([z.literal("/"), z.coerce.string()]),
		PROD: z.boolean(),
		DEV: z.boolean(),
		SSR: z.boolean(),
	},
	client: {
		// Debug
		VITE_DEBUG: z.coerce.boolean().default(false),

		// Version
		VITE_RELEASE_AT: z.string().optional(),
		VITE_RELEASE_GIT_BRANCH: z.string().optional(),
		VITE_RELEASE_GIT_TAG: z.string().optional(),
		VITE_RELEASE_GIT_SHORT_SHA: z.string().optional(),

		// Sentry
		VITE_SENTRY_DSN: clientEnvSchema("VITE_SENTRY_DSN", z.string().url().optional()),
		VITE_SENTRY_TRACES_SAMPLE_RATE: clientEnvSchema(
			"VITE_SENTRY_TRACES_SAMPLE_RATE",
			z.coerce.number().min(0).max(1).step(0.01).default(0.1),
		),
		VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE: clientEnvSchema(
			"VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE",
			z.coerce.number().min(0).max(1).step(0.01).default(0.1),
		).default(0.1),
		VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: clientEnvSchema(
			"VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE",
			z.coerce.number().min(0).max(1).step(0.01).default(0.5),
		),

		// MSAL
		VITE_MSAL_CLIENT_ID: clientEnvSchema("VITE_MSAL_CLIENT_ID", z.string().uuid()),
		VITE_MSAL_DOMAIN: clientEnvSchema("VITE_MSAL_DOMAIN", z.string().url()),
		VITE_MSAL_LOGIN_AUTHORITY: clientEnvSchema(
			"VITE_MSAL_LOGIN_AUTHORITY",
			z.string().startsWith("B2C_1_"),
		),
		VITE_MSAL_REGISTER_AUTHORITY: clientEnvSchema(
			"VITE_MSAL_REGISTER_AUTHORITY",
			z.string().startsWith("B2C_1_").optional(),
		),
		VITE_MSAL_PASSWORD_RESET_AUTHORITY: clientEnvSchema(
			"VITE_MSAL_PASSWORD_RESET_AUTHORITY",
			z.string().startsWith("B2C_1_"),
		),
		VITE_MSAL_KNOWN_AUTHORITY: clientEnvSchema("VITE_MSAL_KNOWN_AUTHORITY", z.string()),
		VITE_MSAL_PERMISSION_URL: clientEnvSchema("VITE_MSAL_PERMISSION_URL", z.string()),

		// Corbion
		VITE_CORBION_API_ENDPOINT: clientEnvSchema("VITE_CORBION_API_ENDPOINT", z.string().url()),
		VITE_CORBION_SUPPORT_EMAIL: clientEnvSchema(
			"VITE_CORBION_SUPPORT_EMAIL",
			z.string().email().default("calculators@corbion.com"),
		),
		VITE_CORBION_CLIENT_HANDLE_HEADER: clientEnvSchema(
			"VITE_CORBION_CLIENT_HANDLE_HEADER",
			z.string().default("X-Corbion-Client-Handle"),
		),
	},
	runtimeEnvStrict: {
		// Vite
		MODE: import.meta.env.MODE,
		BASE_URL: import.meta.env.BASE_URL,
		PROD: import.meta.env.PROD,
		DEV: import.meta.env.DEV,
		SSR: import.meta.env.SSR,

		// Debug
		VITE_DEBUG: import.meta.env.VITE_DEBUG,

		// Version
		VITE_RELEASE_AT: import.meta.env.VITE_RELEASE_AT,
		VITE_RELEASE_GIT_BRANCH: import.meta.env.VITE_RELEASE_GIT_BRANCH,
		VITE_RELEASE_GIT_TAG: import.meta.env.VITE_RELEASE_GIT_TAG,
		VITE_RELEASE_GIT_SHORT_SHA: import.meta.env.VITE_RELEASE_GIT_SHORT_SHA,

		// Sentry
		VITE_SENTRY_DSN:
			windowOrFallback("SENTRY_DSN", import.meta.env.VITE_SENTRY_DSN) || import.meta.env.PROD
				? "https://e0797e3718f7481e9329ccf15131bf7e@sentry.breuk.dev/73"
				: undefined,
		VITE_SENTRY_TRACES_SAMPLE_RATE: windowOrFallback(
			"SENTRY_TRACES_SAMPLE_RATE",
			import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
		),
		VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE: windowOrFallback(
			"SENTRY_REPLAYS_SESSION_SAMPLE_RATE",
			import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
		),
		VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: windowOrFallback(
			"SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE",
			import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
		),

		// MSAL
		VITE_MSAL_CLIENT_ID: windowOrFallback("MSAL_CLIENT_ID", import.meta.env.VITE_MSAL_CLIENT_ID),
		VITE_MSAL_DOMAIN: windowOrFallback("MSAL_DOMAIN", import.meta.env.VITE_MSAL_DOMAIN),
		VITE_MSAL_LOGIN_AUTHORITY: windowOrFallback(
			"MSAL_LOGIN_AUTHORITY",
			import.meta.env.VITE_MSAL_LOGIN_AUTHORITY,
		),
		VITE_MSAL_REGISTER_AUTHORITY: windowOrFallback(
			"MSAL_REGISTER_AUTHORITY",
			import.meta.env.VITE_MSAL_REGISTER_AUTHORITY,
		),
		VITE_MSAL_PASSWORD_RESET_AUTHORITY: windowOrFallback(
			"MSAL_PASSWORD_RESET_AUTHORITY",
			import.meta.env.VITE_MSAL_PASSWORD_RESET_AUTHORITY,
		),
		VITE_MSAL_KNOWN_AUTHORITY: windowOrFallback(
			"MSAL_KNOWN_AUTHORITY",
			import.meta.env.VITE_MSAL_KNOWN_AUTHORITY,
		),
		VITE_MSAL_PERMISSION_URL: windowOrFallback(
			"MSAL_PERMISSION_URL",
			import.meta.env.VITE_MSAL_PERMISSION_URL,
		),

		// Corbion
		VITE_CORBION_API_ENDPOINT: windowOrFallback(
			"CORBION_API_ENDPOINT",
			import.meta.env.VITE_CORBION_API_ENDPOINT,
		),
		VITE_CORBION_SUPPORT_EMAIL: windowOrFallback(
			"CORBION_SUPPORT_EMAIL",
			import.meta.env.VITE_CORBION_SUPPORT_EMAIL,
		),
		VITE_CORBION_CLIENT_HANDLE_HEADER: windowOrFallback(
			"CORBION_CLIENT_HANDLE_HEADER",
			import.meta.env.VITE_CORBION_CLIENT_HANDLE_HEADER,
		),
	},
	isServer: typeof window !== "undefined",
	skipValidation:
		!!import.meta.env.SKIP_ENV_VALIDATION &&
		import.meta.env.SKIP_ENV_VALIDATION !== "false" &&
		import.meta.env.SKIP_ENV_VALIDATION !== "0",

	/**
	 * By default, this library will feed the environment variables directly to the Zod validator.
	 *
	 * This means that if you have an empty string for a value that is supposed to be a number (e.g.
	 * `PORT=` in a ".env" file), Zod will incorrectly flag it as a type mismatch violation.
	 * Additionally, if you have an empty string for a value that is supposed to be a string with a
	 * default value (e.g. `DOMAIN=` in an ".env" file), the default value will never be applied.
	 *
	 * In order to solve these issues, we recommend that all new projects explicitly specify this
	 * option as true.
	 */
	emptyStringAsUndefined: true,
});

function windowOrFallback(windowKey: keyof typeof window.__CONFIG__, fallback?: string) {
	if (typeof window === "undefined") {
		return fallback;
	}

	return window?.__CONFIG__?.[windowKey] || fallback;
}

function clientEnvSchema<T extends z.ZodTypeAny, K extends string>(
	key: K,
	schema: T,
): z.ZodUnion<[T, z.ZodEffects<z.ZodLiteral<`__${K}__`>, undefined>]> {
	return schema.or(z.literal(`__${key}__`).transform(() => undefined));
}
