import { HttpLink } from "@apollo/client/link/http/HttpLink";
import type { ServiceHandle } from "@/graphql/graphql";
import { clientEnv } from "@/lib/env/client";
import { getOriginFromURL } from "@/utils/getOriginFromURL";

export const httpLink = new HttpLink({
	uri: `${getOriginFromURL(clientEnv.VITE_CORBION_API_ENDPOINT ?? "")}/graphql`,
	headers: {
		[clientEnv.VITE_CORBION_CLIENT_HANDLE_HEADER ?? ""]: "CNMIM" satisfies ServiceHandle,
	},
});
