export const MAX_INGREDIENT_PERCENTAGE = 10;
export const MIN_CONTROL_DAYS = 2;
export const DEFAULT_CONTROL_DAYS = 5;
export const MAX_CONTROL_DAYS = 10;
export const MAX_DAYS_IN_RESULTS = 40;
export const COLORS: ReadonlyArray<
	Record<"full" | "transparent", { class: string; rgba: string }>
> = [
	{
		full: { class: "bg-cyan-500", rgba: "rgba(6,182,212,1)" },
		transparent: { class: "bg-cyan-500/25", rgba: "rgba(6,182,212,0.25)" },
	},
	{
		full: { class: "bg-amber-500", rgba: "rgba(245,158,11,1)" },
		transparent: { class: "bg-amber-500/25", rgba: "rgba(245,158,11,0.25)" },
	},
	{
		full: { class: "bg-red-500", rgba: "rgba(239,68,68,1)" },
		transparent: { class: "bg-red-500/25", rgba: "rgba(239,68,68,0.25)" },
	},
	{
		full: { class: "bg-purple-500", rgba: "rgba(168,85,247,1)" },
		transparent: { class: "bg-purple-500/25", rgba: "rgba(168,85,247,0.25)" },
	},
	{
		full: { class: "bg-lime-500", rgba: "rgba(132,204,22,1)" },
		transparent: { class: "bg-lime-500/25", rgba: "rgba(132,204,22,0.25)" },
	},
	{
		full: { class: "bg-rose-500", rgba: "rgba(244,63,94,1)" },
		transparent: { class: "bg-rose-500/25", rgba: "rgba(244,63,94,0.25)" },
	},
	{
		full: { class: "bg-green-500", rgba: "rgba(34,197,94,1)" },
		transparent: { class: "bg-green-500/25", rgba: "rgba(34,197,94,0.25)" },
	},
	{
		full: { class: "bg-fuchsia-500", rgba: "rgba(217,70,239,1)" },
		transparent: { class: "bg-fuchsia-500/25", rgba: "rgba(217,70,239,0.25)" },
	},
	{
		full: { class: "bg-pink-500", rgba: "rgba(236,72,153,1)" },
		transparent: { class: "bg-pink-500/25", rgba: "rgba(236,72,153,0.25)" },
	},
	{
		full: { class: "bg-teal-500", rgba: "rgba(20,184,166,1)" },
		transparent: { class: "bg-teal-500/25", rgba: "rgba(20,184,166,0.25)" },
	},
	{
		full: { class: "bg-blue-500", rgba: "rgba(59,130,246,1)" },
		transparent: { class: "bg-blue-500/25", rgba: "rgba(59,130,246,0.25)" },
	},
	{
		full: { class: "bg-yellow-500", rgba: "rgba(234,179,8,1)" },
		transparent: { class: "bg-yellow-500/25", rgba: "rgba(234,179,8,0.25)" },
	},
	{
		full: { class: "bg-indigo-500", rgba: "rgba(99,102,241,1)" },
		transparent: { class: "bg-indigo-500/25", rgba: "rgba(99,102,241,0.25)" },
	},
	{
		full: { class: "bg-orange-500", rgba: "rgba(249,115,22,1)" },
		transparent: { class: "bg-orange-500/25", rgba: "rgba(249,115,22,0.25)" },
	},
	{
		full: { class: "bg-violet-500", rgba: "rgba(139,92,246,1)" },
		transparent: { class: "bg-violet-500/25", rgba: "rgba(139,92,246,0.25)" },
	},
	{
		full: { class: "bg-emerald-500", rgba: "rgba(16,185,129,1)" },
		transparent: { class: "bg-emerald-500/25", rgba: "rgba(16,185,129,0.25)" },
	},
	{
		full: { class: "bg-sky-500", rgba: "rgba(14,165,233,1)" },
		transparent: { class: "bg-sky-500/25", rgba: "rgba(14,165,233,0.25)" },
	},
] as const;

export const STORAGE_PREFIX = "CNMIM";
export const STORAGE_SEPARATOR = "_";
