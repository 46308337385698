/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
	[_ in K]?: never;
};
export type Incremental<T> =
	| T
	| { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
	DateTime: { input: string; output: string };
	/**
	 * The `JSON` scalar type represents JSON values as specified by
	 * [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf).
	 */
	JSON: { input: object; output: object };
	/**
	 * Permission custom scalar type, it can either be a boolean or a number. Check the given
	 * permission for the exact type
	 */
	PermissionValue: { input: boolean | number; output: boolean | number };
};

export type AcidicValues = {
	__typename: "AcidicValues";
	b: Scalars["Float"]["output"];
	lf: Scalars["Float"]["output"];
	mic: Scalars["Float"]["output"];
};

export type AcidicValuesCreateInput = {
	b: Scalars["Float"]["input"];
	lf: Scalars["Float"]["input"];
	mic: Scalars["Float"]["input"];
};

export type ActivityCreateInput = {
	action: Scalars["String"]["input"];
	meta?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type ActivityLog = {
	__typename: "ActivityLog";
	action: Scalars["String"]["output"];
	meta: Scalars["JSON"]["output"];
	timestamp: Scalars["DateTime"]["output"];
};

export type ActivityLogEdge = {
	__typename: "ActivityLogEdge";
	cursor: Scalars["String"]["output"];
	node: ActivityLog;
};

export type ActivityQueryFindManyWhereInput = {
	action?: InputMaybe<StringFilter>;
	actor?: InputMaybe<Scalars["String"]["input"]>;
	timestamp?: InputMaybe<DateTimeFilter>;
};

export type ActivityQueryOrderByInput = {
	timestamp?: InputMaybe<OrderByDirection>;
};

export type AllActionsFindManyWhereInput = {
	action?: InputMaybe<StringFilter>;
	timestamp?: InputMaybe<DateTimeFilter>;
};

export type AllActionsQueryOrderByInput = {
	sequence?: InputMaybe<OrderByDirection>;
	timestamp?: InputMaybe<OrderByDirection>;
};

export type AntimicrobialProduct = CnmimPortfolioProduct & {
	__typename: "AntimicrobialProduct";
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	regionAvailability: Array<RegionAvailability>;
};

export type BaseProduct = {
	__typename: "BaseProduct";
	archived: Scalars["Boolean"]["output"];
	cnmim?: Maybe<CnmimProduct>;
	createdAt: Scalars["DateTime"]["output"];
	id: Scalars["String"]["output"];
	name: Scalars["String"]["output"];
	sodiumPercentage?: Maybe<Scalars["Float"]["output"]>;
	type: ProductType;
	updatedAt: Scalars["DateTime"]["output"];
};

export type BaseProductCreateOneInput = {
	cnmim?: InputMaybe<CnmimProductCreateOneInput>;
	name: Scalars["String"]["input"];
	sodiumPercentage: Scalars["Float"]["input"];
	type: ProductType;
};

export type BaseProductEdge = {
	__typename: "BaseProductEdge";
	cursor: Scalars["String"]["output"];
	node: BaseProduct;
};

export type BaseProductUpdateDataOneInput = {
	cnmim?: InputMaybe<CnmimProductUpdateOneInput>;
	name?: InputMaybe<Scalars["String"]["input"]>;
	sodiumPercentage?: InputMaybe<Scalars["Float"]["input"]>;
	type?: InputMaybe<ProductType>;
};

export type BaseProductUpdateOneInput = {
	data: BaseProductUpdateDataOneInput;
	where: BaseProductWhereUniqueInput;
};

export type BaseProductWhereUniqueInput = {
	id: Scalars["String"]["input"];
};

export type CnmimBakersInput = {
	bakeLoss: Scalars["Float"]["input"];
	flourWeight: Scalars["Float"]["input"];
	totalBatchWeight: Scalars["Float"]["input"];
};

export type CnmimMoldGroupGrowthResult = {
	__typename: "CNMIMMoldGroupGrowthResult";
	minPredictedDays: Scalars["Int"]["output"];
	moldGrowthFormulations?: Maybe<Array<CnmimMoldGrowthResult>>;
	type: MoldGroup;
	warning: Scalars["Boolean"]["output"];
};

export type CnmimMoldGroupGrowthResults = {
	__typename: "CNMIMMoldGroupGrowthResults";
	id: Scalars["ID"]["output"];
	moldGroupResults: Array<CnmimMoldGroupGrowthResult>;
};

export type CnmimMoldGrowthFormulaInput = {
	aW: Scalars["Float"]["input"];
	bakersInput?: InputMaybe<CnmimBakersInput>;
	id: Scalars["String"]["input"];
	ingredients: Array<CnmimProductIngredientInput>;
	moisture: Scalars["Float"]["input"];
	name: Scalars["String"]["input"];
	pH: Scalars["Float"]["input"];
};

export type CnmimMoldGrowthReferenceFormulaInput = {
	aW: Scalars["Float"]["input"];
	bakersInput?: InputMaybe<CnmimBakersInput>;
	daysToVisualMoldGrowth?: InputMaybe<Scalars["Float"]["input"]>;
	id: Scalars["String"]["input"];
	ingredients: Array<CnmimProductIngredientInput>;
	moisture: Scalars["Float"]["input"];
	name: Scalars["String"]["input"];
	pH: Scalars["Float"]["input"];
};

export type CnmimMoldGrowthResult = {
	__typename: "CNMIMMoldGrowthResult";
	id: Scalars["ID"]["output"];
	isolatedFrom: Scalars["String"]["output"];
	name: Scalars["String"]["output"];
	predictedDays: Scalars["Int"]["output"];
	strainCode: Scalars["String"]["output"];
	warning: Scalars["Boolean"]["output"];
};

export type CnmimPortfolioProduct = {
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	regionAvailability: Array<RegionAvailability>;
};

export type CnmimProduct = {
	__typename: "CNMIMProduct";
	AA: Scalars["Float"]["output"];
	PA: Scalars["Float"]["output"];
	SA: Scalars["Float"]["output"];
	regionAvailability: Array<RegionAvailability>;
};

export type CnmimProductCreateOneInput = {
	AA: Scalars["Float"]["input"];
	PA: Scalars["Float"]["input"];
	SA: Scalars["Float"]["input"];
	availableRegions: Array<CnmimProductRegionAvailabilityDataInput>;
};

export type CnmimProductIngredientInput = {
	id: Scalars["ID"]["input"];
	weightForWeight: Scalars["Float"]["input"];
};

export type CnmimProductRegionAvailabilityDataInput = {
	available: Scalars["Boolean"]["input"];
	region: RegionFindUniqueInput;
};

export type CnmimProductUpdateDataOneInput = {
	AA?: InputMaybe<Scalars["Float"]["input"]>;
	PA?: InputMaybe<Scalars["Float"]["input"]>;
	SA?: InputMaybe<Scalars["Float"]["input"]>;
	availableRegions?: InputMaybe<Array<CnmimProductRegionAvailabilityDataInput>>;
};

export type CnmimProductUpdateOneInput = {
	create?: InputMaybe<CnmimProductCreateOneInput>;
	delete?: InputMaybe<Scalars["Boolean"]["input"]>;
	update?: InputMaybe<CnmimProductUpdateDataOneInput>;
};

export type CnmimSodiumFormulaInput = {
	bakersInput: CnmimBakersInput;
	id: Scalars["String"]["input"];
	ingredients: Array<CnmimProductIngredientInput>;
	name: Scalars["String"]["input"];
	servingSizeInGrams: Scalars["Float"]["input"];
};

export type CnmimSodiumResult = {
	__typename: "CNMIMSodiumResult";
	formulationId: Scalars["ID"]["output"];
	sodium: Scalars["Float"]["output"];
};

export type CompanyReportFindManyWhereInput = {
	accountEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
	/** Timestamp applies to the activity record, not the user. Default value is beginning of the month */
	timestamp?: InputMaybe<DateTimeFilter>;
};

export type CompanyReportQueryOrderByInput = {
	lastLoggedIn?: InputMaybe<OrderByDirection>;
};

export type ConnectRoleInput = {
	handle?: InputMaybe<Scalars["String"]["input"]>;
	id?: InputMaybe<Scalars["String"]["input"]>;
};

export type ConnectToUniqueGroupInput = {
	handle?: InputMaybe<Scalars["String"]["input"]>;
	id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CorbionProduct = CnmimPortfolioProduct & {
	__typename: "CorbionProduct";
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	regionAvailability: Array<RegionAvailability>;
};

export type DateTimeFilter = {
	equals?: InputMaybe<Scalars["DateTime"]["input"]>;
	gt?: InputMaybe<Scalars["DateTime"]["input"]>;
	gte?: InputMaybe<Scalars["DateTime"]["input"]>;
	in?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
	lt?: InputMaybe<Scalars["DateTime"]["input"]>;
	lte?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DefaultunitofweightEnumFilter = {
	equals?: InputMaybe<UnitOfWeight>;
	in?: InputMaybe<Array<UnitOfWeight>>;
	not?: InputMaybe<UnitOfWeight>;
	notIn?: InputMaybe<Array<UnitOfWeight>>;
};

export type DomainRule = {
	__typename: "DomainRule";
	createdAt: Scalars["DateTime"]["output"];
	defaultGroup?: Maybe<Group>;
	domain: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	reason?: Maybe<Scalars["String"]["output"]>;
	type: DomainRuleType;
	updatedAt: Scalars["DateTime"]["output"];
};

export type DomainRuleCreateOneInput = {
	defaultGroup?: InputMaybe<ConnectToUniqueGroupInput>;
	domain: Scalars["String"]["input"];
	reason?: InputMaybe<Scalars["String"]["input"]>;
	type: DomainRuleType;
};

export type DomainRuleEdge = {
	__typename: "DomainRuleEdge";
	cursor: Scalars["String"]["output"];
	node: DomainRule;
};

export type DomainRuleFindManyWhereInput = {
	createdAt?: InputMaybe<DateTimeFilter>;
	domain?: InputMaybe<StringFilter>;
	id?: InputMaybe<IdFilter>;
	reason?: InputMaybe<StringFilter>;
	type?: InputMaybe<TypeEnumFilter>;
	updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DomainRuleFindUniqueInput = {
	domain?: InputMaybe<Scalars["String"]["input"]>;
	id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type DomainRuleOrderByInput = {
	createdAt?: InputMaybe<OrderByDirection>;
	domain?: InputMaybe<OrderByDirection>;
	id?: InputMaybe<OrderByDirection>;
	reason?: InputMaybe<OrderByDirection>;
	type?: InputMaybe<OrderByDirection>;
	updatedAt?: InputMaybe<OrderByDirection>;
};

export type DomainRuleType = "ALLOW" | "BLOCK";

export type DomainRuleUpdateDataOneInput = {
	defaultGroup?: InputMaybe<ConnectToUniqueGroupInput>;
	domain?: InputMaybe<Scalars["String"]["input"]>;
	reason?: InputMaybe<Scalars["String"]["input"]>;
	type: DomainRuleType;
};

export type DomainRuleUpdateOneInput = {
	data: DomainRuleUpdateDataOneInput;
	where: DomainRuleFindUniqueInput;
};

export type Group = {
	__typename: "Group";
	createdAt: Scalars["DateTime"]["output"];
	description: Scalars["String"]["output"];
	handle: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	price: Scalars["Int"]["output"];
	roles: Array<Role>;
	title: Scalars["String"]["output"];
	updatedAt: Scalars["DateTime"]["output"];
	userCount: Scalars["Int"]["output"];
};

export type GroupCreateOneInput = {
	description: Scalars["String"]["input"];
	handle: Scalars["String"]["input"];
	price: Scalars["Int"]["input"];
	/** Roles acts like a set, `null` will ignore this property for updating. `roles: ["handle"]` */
	roles: Array<Scalars["String"]["input"]>;
	title: Scalars["String"]["input"];
};

export type GroupEdge = {
	__typename: "GroupEdge";
	cursor: Scalars["String"]["output"];
	node: Group;
};

export type GroupFindManyWhereInput = {
	createdAt?: InputMaybe<DateTimeFilter>;
	description?: InputMaybe<StringFilter>;
	handle?: InputMaybe<StringFilter>;
	id?: InputMaybe<IdFilter>;
	price?: InputMaybe<IntFloatFilter>;
	title?: InputMaybe<StringFilter>;
	updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GroupFindUniqueInput = {
	handle?: InputMaybe<Scalars["String"]["input"]>;
	id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GroupOrderByInput = {
	createdAt?: InputMaybe<OrderByDirection>;
	description?: InputMaybe<OrderByDirection>;
	handle?: InputMaybe<OrderByDirection>;
	id?: InputMaybe<OrderByDirection>;
	price?: InputMaybe<OrderByDirection>;
	title?: InputMaybe<OrderByDirection>;
	updatedAt?: InputMaybe<OrderByDirection>;
};

export type GroupUpdateDataOneInput = {
	description?: InputMaybe<Scalars["String"]["input"]>;
	handle?: InputMaybe<Scalars["String"]["input"]>;
	price?: InputMaybe<Scalars["Int"]["input"]>;
	/** Roles acts like a set, `null` will ignore this property for updating. `roles: ["handle"]` */
	roles?: InputMaybe<Array<Scalars["String"]["input"]>>;
	title?: InputMaybe<Scalars["String"]["input"]>;
};

export type GroupUpdateOneInput = {
	data: GroupUpdateDataOneInput;
	where: GroupFindUniqueInput;
};

export type IdFilter = {
	equals?: InputMaybe<Scalars["ID"]["input"]>;
	in?: InputMaybe<Scalars["ID"]["input"]>;
	notIn?: InputMaybe<Scalars["ID"]["input"]>;
};

export type IntFloatFilter = {
	equals?: InputMaybe<Scalars["Float"]["input"]>;
	gt?: InputMaybe<Scalars["Float"]["input"]>;
	gte?: InputMaybe<Scalars["Float"]["input"]>;
	in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
	lt?: InputMaybe<Scalars["Float"]["input"]>;
	lte?: InputMaybe<Scalars["Float"]["input"]>;
};

export type JsonReport = {
	__typename: "JSONReport";
	data: Array<Scalars["JSON"]["output"]>;
	headers: Array<Scalars["String"]["output"]>;
	title?: Maybe<Scalars["String"]["output"]>;
};

export type Mold = {
	__typename: "Mold";
	AA: AcidicValues;
	CSNumber: Scalars["String"]["output"];
	PA: AcidicValues;
	SA: AcidicValues;
	aW: WaterActivity;
	createdAt?: Maybe<Scalars["DateTime"]["output"]>;
	id: Scalars["String"]["output"];
	isolatedFrom: Scalars["String"]["output"];
	moldGroup: MoldGroup;
	name: Scalars["String"]["output"];
	strainCode: Scalars["String"]["output"];
	updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type MoldCreateOneInput = {
	AA: AcidicValuesCreateInput;
	CSNumber: Scalars["ID"]["input"];
	PA: AcidicValuesCreateInput;
	SA: AcidicValuesCreateInput;
	aW: WaterActivityCreateInput;
	isolatedFrom: Scalars["String"]["input"];
	moldGroup: MoldGroup;
	name: Scalars["String"]["input"];
	strainCode: Scalars["String"]["input"];
};

export type MoldEdge = {
	__typename: "MoldEdge";
	cursor: Scalars["String"]["output"];
	node: Mold;
};

export type MoldFindManyWhereInput = {
	CSNumber?: InputMaybe<IdFilter>;
	createdAt?: InputMaybe<DateTimeFilter>;
	id?: InputMaybe<IdFilter>;
	isolatedFrom?: InputMaybe<StringFilter>;
	moldGroup?: InputMaybe<MoldGroupFilter>;
	name?: InputMaybe<StringFilter>;
	strainCode?: InputMaybe<StringFilter>;
	updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MoldGroup = "COMMON_FOOD" | "PRESERVATIVE";

export type MoldGroupFilter = {
	equals?: InputMaybe<MoldGroup>;
	in?: InputMaybe<Array<MoldGroup>>;
	not?: InputMaybe<MoldGroup>;
	notIn?: InputMaybe<Array<MoldGroup>>;
};

export type MoldOrderByInput = {
	CSNumber?: InputMaybe<OrderByDirection>;
	createdAt?: InputMaybe<OrderByDirection>;
	moldGroup?: InputMaybe<OrderByDirection>;
	name?: InputMaybe<OrderByDirection>;
	strainCode?: InputMaybe<OrderByDirection>;
	updatedAt?: InputMaybe<OrderByDirection>;
};

export type MoldUpdateDataOneInput = {
	AA?: InputMaybe<AcidicValuesCreateInput>;
	CSNumber?: InputMaybe<Scalars["ID"]["input"]>;
	PA?: InputMaybe<AcidicValuesCreateInput>;
	SA?: InputMaybe<AcidicValuesCreateInput>;
	aW?: InputMaybe<WaterActivityCreateInput>;
	isolatedFrom?: InputMaybe<Scalars["String"]["input"]>;
	moldGroup?: InputMaybe<MoldGroup>;
	name?: InputMaybe<Scalars["String"]["input"]>;
	strainCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type MoldUpdateOneInput = {
	data: MoldUpdateDataOneInput;
	where: MoldWhereUniqueInput;
};

export type MoldWhereUniqueInput = {
	CSNumber?: InputMaybe<Scalars["String"]["input"]>;
	id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Mutation = {
	__typename: "Mutation";
	acceptPrivacyStatement: Scalars["Boolean"]["output"];
	domainRuleCreate: DomainRule;
	domainRuleDelete: DomainRule;
	domainRuleUpdate: DomainRule;
	domainRulesUpsert: PaginatedDomainRule;
	groupCreate: Group;
	groupDelete: Group;
	groupUpdate: Group;
	logActivity: Scalars["Boolean"]["output"];
	moldCreate: Mold;
	moldDelete: Mold;
	moldUpdate: Mold;
	privacyStatementCreate: PrivacyStatement;
	privacyStatementDelete: Scalars["Boolean"]["output"];
	privacyStatementUpdate: PrivacyStatement;
	productArchive: BaseProduct;
	productCreate: BaseProduct;
	productUnarchive: BaseProduct;
	productUpdate: BaseProduct;
	roleCreate: Role;
	roleDelete: Role;
	roleUpdate: Role;
	selfUpdate: User;
	userDelete: User;
	userUpdate: User;
	userUpdateMany: Scalars["Boolean"]["output"];
};

export type MutationDomainRuleCreateArgs = {
	input: DomainRuleCreateOneInput;
};

export type MutationDomainRuleDeleteArgs = {
	input: DomainRuleFindUniqueInput;
};

export type MutationDomainRuleUpdateArgs = {
	input: DomainRuleUpdateOneInput;
};

export type MutationDomainRulesUpsertArgs = {
	input: Array<DomainRuleCreateOneInput>;
};

export type MutationGroupCreateArgs = {
	input: GroupCreateOneInput;
};

export type MutationGroupDeleteArgs = {
	input: GroupFindUniqueInput;
};

export type MutationGroupUpdateArgs = {
	input: GroupUpdateOneInput;
};

export type MutationLogActivityArgs = {
	input: ActivityCreateInput;
};

export type MutationMoldCreateArgs = {
	input: MoldCreateOneInput;
};

export type MutationMoldDeleteArgs = {
	input: MoldWhereUniqueInput;
};

export type MutationMoldUpdateArgs = {
	input: MoldUpdateOneInput;
};

export type MutationPrivacyStatementCreateArgs = {
	input: PrivacyStatementCreateOneDataInput;
};

export type MutationPrivacyStatementDeleteArgs = {
	input: PrivacyStatementWhereUniqueInput;
};

export type MutationPrivacyStatementUpdateArgs = {
	input: PrivacyStatementUpdateOneInput;
};

export type MutationProductArchiveArgs = {
	id: Scalars["String"]["input"];
};

export type MutationProductCreateArgs = {
	input: BaseProductCreateOneInput;
};

export type MutationProductUnarchiveArgs = {
	id: Scalars["String"]["input"];
};

export type MutationProductUpdateArgs = {
	input: BaseProductUpdateOneInput;
};

export type MutationRoleCreateArgs = {
	input: RoleCreateOneInput;
};

export type MutationRoleDeleteArgs = {
	input: RoleFindUniqueInput;
};

export type MutationRoleUpdateArgs = {
	input: RoleUpdateOneInput;
};

export type MutationSelfUpdateArgs = {
	input: UpdateSelfInput;
};

export type MutationUserDeleteArgs = {
	input: UserFindUniqueInput;
};

export type MutationUserUpdateArgs = {
	input: UserUpdateOneInput;
};

export type MutationUserUpdateManyArgs = {
	input: UserUpdateManyInput;
};

export type OrderByDirection = "ASC" | "DESC";

export type PageInfo = {
	__typename: "PageInfo";
	endCursor?: Maybe<Scalars["String"]["output"]>;
	hasNextPage: Scalars["Boolean"]["output"];
	hasPreviousPage: Scalars["Boolean"]["output"];
	startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedActivityLog = {
	__typename: "PaginatedActivityLog";
	edges?: Maybe<Array<ActivityLogEdge>>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"]["output"];
};

export type PaginatedBaseProduct = {
	__typename: "PaginatedBaseProduct";
	edges?: Maybe<Array<BaseProductEdge>>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"]["output"];
};

export type PaginatedDomainRule = {
	__typename: "PaginatedDomainRule";
	edges?: Maybe<Array<DomainRuleEdge>>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"]["output"];
};

export type PaginatedGroup = {
	__typename: "PaginatedGroup";
	edges?: Maybe<Array<GroupEdge>>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"]["output"];
};

export type PaginatedMold = {
	__typename: "PaginatedMold";
	edges?: Maybe<Array<MoldEdge>>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"]["output"];
};

export type PaginatedPrivacyStatement = {
	__typename: "PaginatedPrivacyStatement";
	edges?: Maybe<Array<PrivacyStatementEdge>>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"]["output"];
};

export type PaginatedReportViewModel = {
	__typename: "PaginatedReportViewModel";
	pageInfo?: Maybe<ReportPageInfo>;
	report: JsonReport;
	totalCount: Scalars["Int"]["output"];
};

export type PaginatedRole = {
	__typename: "PaginatedRole";
	edges?: Maybe<Array<RoleEdge>>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"]["output"];
};

export type PaginatedUser = {
	__typename: "PaginatedUser";
	edges?: Maybe<Array<UserEdge>>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"]["output"];
};

export type Permission = {
	__typename: "Permission";
	handle: Scalars["String"]["output"];
	value: Scalars["PermissionValue"]["output"];
};

export type PermissionCreateOneInput = {
	handle: Scalars["String"]["input"];
	value: Scalars["PermissionValue"]["input"];
};

export type PermissionSchema = {
	__typename: "PermissionSchema";
	handle: Scalars["String"]["output"];
	valueType: Scalars["String"]["output"];
};

export type PortfolioFindManyWhereInput = {
	regionAvailable: RegionFindUniqueInput;
};

export type PrivacyStatement = {
	__typename: "PrivacyStatement";
	content: Scalars["String"]["output"];
	createdAt: Scalars["DateTime"]["output"];
	id: Scalars["ID"]["output"];
	publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
	status: PrivacyStatementStatus;
	updatedAt: Scalars["DateTime"]["output"];
	version: Scalars["Float"]["output"];
};

export type PrivacyStatementCreateOneDataInput = {
	content: Scalars["String"]["input"];
	publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
	service: ServiceHandle;
};

export type PrivacyStatementEdge = {
	__typename: "PrivacyStatementEdge";
	cursor: Scalars["String"]["output"];
	node: PrivacyStatement;
};

export type PrivacyStatementFindManyWhereInput = {
	content?: InputMaybe<StringFilter>;
	createdAt?: InputMaybe<DateTimeFilter>;
	id?: InputMaybe<IdFilter>;
	publishedAt?: InputMaybe<DateTimeFilter>;
	status?: InputMaybe<PrivacyStatementStatusFilter>;
	updatedAt?: InputMaybe<DateTimeFilter>;
	version?: InputMaybe<IntFloatFilter>;
};

export type PrivacyStatementOrderByInput = {
	content?: InputMaybe<OrderByDirection>;
	createdAt?: InputMaybe<OrderByDirection>;
	id?: InputMaybe<OrderByDirection>;
	publishedAt?: InputMaybe<OrderByDirection>;
	updatedAt?: InputMaybe<OrderByDirection>;
	version?: InputMaybe<OrderByDirection>;
};

export type PrivacyStatementStatus = "DRAFT" | "EXPIRED" | "LIVE" | "QUEUED";

export type PrivacyStatementStatusFilter = {
	equals?: InputMaybe<PrivacyStatementStatus>;
	in?: InputMaybe<Array<PrivacyStatementStatus>>;
	not?: InputMaybe<PrivacyStatementStatus>;
	notIn?: InputMaybe<Array<PrivacyStatementStatus>>;
};

export type PrivacyStatementUpdateOneDataInput = {
	content?: InputMaybe<Scalars["String"]["input"]>;
	publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PrivacyStatementUpdateOneInput = {
	data: PrivacyStatementUpdateOneDataInput;
	where: PrivacyStatementWhereUniqueInput;
};

export type PrivacyStatementWhereUniqueInput = {
	id: Scalars["String"]["input"];
};

export type ProductFindManyWhereInput = {
	archived?: InputMaybe<Scalars["Boolean"]["input"]>;
	name?: InputMaybe<StringFilter>;
	type?: InputMaybe<ProductTypeFilter>;
};

export type ProductOrderByInput = {
	createdAt?: InputMaybe<OrderByDirection>;
	name?: InputMaybe<OrderByDirection>;
	type?: InputMaybe<OrderByDirection>;
	updatedAt?: InputMaybe<OrderByDirection>;
};

export type ProductType =
	| "ANTIMICROBIAL_PRODUCT"
	| "CORBION_PRODUCT"
	| "PROTOTYPE_PRODUCT"
	| "PURE_PRODUCT";

export type ProductTypeFilter = {
	equals?: InputMaybe<ProductType>;
	in?: InputMaybe<Array<ProductType>>;
	not?: InputMaybe<ProductType>;
	notIn?: InputMaybe<Array<ProductType>>;
};

export type PrototypeProduct = CnmimPortfolioProduct & {
	__typename: "PrototypeProduct";
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	regionAvailability: Array<RegionAvailability>;
};

export type PureProduct = CnmimPortfolioProduct & {
	__typename: "PureProduct";
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	regionAvailability: Array<RegionAvailability>;
};

export type Query = {
	__typename: "Query";
	CNMIMCalculateMoldGrowth: Array<CnmimMoldGroupGrowthResults>;
	CNMIMCalculateSodium: Array<CnmimSodiumResult>;
	CNMIMPortfolio: Array<CnmimPortfolioProduct>;
	CNMIMReferenceFormulaDefaultDaysToVisualMoldGrowth: Scalars["Int"]["output"];
	activePrivacyStatement: PrivacyStatement;
	activityLogActions: Array<Scalars["String"]["output"]>;
	activityLogs: PaginatedActivityLog;
	domainRule: DomainRule;
	domainRules: PaginatedDomainRule;
	generateInviteLink: Scalars["String"]["output"];
	getAllActionsReport?: Maybe<PaginatedReportViewModel>;
	getCompanyReport?: Maybe<PaginatedReportViewModel>;
	getUsersReport?: Maybe<PaginatedReportViewModel>;
	group: Group;
	groups: PaginatedGroup;
	hasAcceptedPrivacyStatement: Scalars["Boolean"]["output"];
	me: User;
	mold: Mold;
	molds: PaginatedMold;
	myPermissionsHaveChanged: Scalars["Boolean"]["output"];
	permissions: Array<ServicePermissions>;
	privacyStatement: PrivacyStatement;
	privacyStatements: PaginatedPrivacyStatement;
	product: BaseProduct;
	products: PaginatedBaseProduct;
	region: Region;
	regions: Array<Region>;
	role: Role;
	roles: PaginatedRole;
	user: User;
	users: PaginatedUser;
};

export type QueryCnmimCalculateMoldGrowthArgs = {
	additionalInformation?: InputMaybe<Scalars["String"]["input"]>;
	foodType?: InputMaybe<Scalars["String"]["input"]>;
	formulas: Array<CnmimMoldGrowthFormulaInput>;
	productName?: InputMaybe<Scalars["String"]["input"]>;
	reference: CnmimMoldGrowthReferenceFormulaInput;
	regionHandle: Scalars["String"]["input"];
};

export type QueryCnmimCalculateSodiumArgs = {
	formulas: Array<CnmimSodiumFormulaInput>;
};

export type QueryCnmimPortfolioArgs = {
	input?: InputMaybe<PortfolioFindManyWhereInput>;
};

export type QueryActivePrivacyStatementArgs = {
	service: Scalars["String"]["input"];
};

export type QueryActivityLogsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Float"]["input"]>;
	orderBy?: InputMaybe<ActivityQueryOrderByInput>;
	where?: InputMaybe<ActivityQueryFindManyWhereInput>;
};

export type QueryDomainRuleArgs = {
	input: DomainRuleFindUniqueInput;
};

export type QueryDomainRulesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	orderBy?: InputMaybe<DomainRuleOrderByInput>;
	where?: InputMaybe<DomainRuleFindManyWhereInput>;
};

export type QueryGenerateInviteLinkArgs = {
	service: ServiceHandle;
};

export type QueryGetAllActionsReportArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Float"]["input"]>;
	orderBy?: InputMaybe<AllActionsQueryOrderByInput>;
	where?: InputMaybe<AllActionsFindManyWhereInput>;
};

export type QueryGetCompanyReportArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Float"]["input"]>;
	orderBy?: InputMaybe<CompanyReportQueryOrderByInput>;
	where?: InputMaybe<CompanyReportFindManyWhereInput>;
};

export type QueryGetUsersReportArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Float"]["input"]>;
	orderBy?: InputMaybe<UserReportQueryOrderByInput>;
	where?: InputMaybe<UserReportFindManyWhereInput>;
};

export type QueryGroupArgs = {
	input: GroupFindUniqueInput;
};

export type QueryGroupsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	orderBy?: InputMaybe<GroupOrderByInput>;
	where?: InputMaybe<GroupFindManyWhereInput>;
};

export type QueryMoldArgs = {
	input: MoldWhereUniqueInput;
};

export type QueryMoldsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	orderBy?: InputMaybe<MoldOrderByInput>;
	where?: InputMaybe<MoldFindManyWhereInput>;
};

export type QueryPrivacyStatementArgs = {
	input: PrivacyStatementWhereUniqueInput;
};

export type QueryPrivacyStatementsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	orderBy?: InputMaybe<PrivacyStatementOrderByInput>;
	serviceHandle: ServiceHandle;
	where?: InputMaybe<PrivacyStatementFindManyWhereInput>;
};

export type QueryProductArgs = {
	input: BaseProductWhereUniqueInput;
};

export type QueryProductsArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	orderBy?: InputMaybe<ProductOrderByInput>;
	where?: InputMaybe<ProductFindManyWhereInput>;
};

export type QueryRegionArgs = {
	input: RegionFindUniqueInput;
};

export type QueryRoleArgs = {
	input: RoleFindUniqueInput;
};

export type QueryRolesArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	orderBy?: InputMaybe<RoleOrderByInput>;
	where?: InputMaybe<RoleFindManyWhereInput>;
};

export type QueryUserArgs = {
	input: UserFindUniqueInput;
};

export type QueryUsersArgs = {
	after?: InputMaybe<Scalars["String"]["input"]>;
	before?: InputMaybe<Scalars["String"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
	last?: InputMaybe<Scalars["Int"]["input"]>;
	orderBy?: InputMaybe<UserOrderByInput>;
	where?: InputMaybe<UserFindManyWhereInput>;
};

export type Region = {
	__typename: "Region";
	createdAt: Scalars["DateTime"]["output"];
	handle: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	updatedAt: Scalars["DateTime"]["output"];
};

export type RegionAvailability = {
	__typename: "RegionAvailability";
	available: Scalars["Boolean"]["output"];
	region: Region;
};

export type RegionFindUniqueInput = {
	handle?: InputMaybe<Scalars["String"]["input"]>;
	id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ReportPageInfo = {
	__typename: "ReportPageInfo";
	endCursor?: Maybe<Scalars["String"]["output"]>;
	hasNextPage: Scalars["Boolean"]["output"];
	hasPreviousPage: Scalars["Boolean"]["output"];
	startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type Role = {
	__typename: "Role";
	createdAt: Scalars["DateTime"]["output"];
	handle: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	permissions: Array<Permission>;
	title: Scalars["String"]["output"];
	updatedAt: Scalars["DateTime"]["output"];
};

export type RoleCreateOneInput = {
	handle: Scalars["String"]["input"];
	permissions: Array<PermissionCreateOneInput>;
	title: Scalars["String"]["input"];
};

export type RoleEdge = {
	__typename: "RoleEdge";
	cursor: Scalars["String"]["output"];
	node: Role;
};

export type RoleFindManyWhereInput = {
	createdAt?: InputMaybe<DateTimeFilter>;
	handle?: InputMaybe<StringFilter>;
	id?: InputMaybe<IdFilter>;
	title?: InputMaybe<StringFilter>;
	updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RoleFindUniqueInput = {
	handle?: InputMaybe<Scalars["String"]["input"]>;
	id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type RoleOrderByInput = {
	createdAt?: InputMaybe<OrderByDirection>;
	handle?: InputMaybe<OrderByDirection>;
	id?: InputMaybe<OrderByDirection>;
	title?: InputMaybe<OrderByDirection>;
	updatedAt?: InputMaybe<OrderByDirection>;
};

export type RoleUpdateDataOneInput = {
	handle?: InputMaybe<Scalars["String"]["input"]>;
	permissions?: InputMaybe<Array<PermissionCreateOneInput>>;
	title?: InputMaybe<Scalars["String"]["input"]>;
};

export type RoleUpdateOneInput = {
	data: RoleUpdateDataOneInput;
	where: RoleFindUniqueInput;
};

/** This can be a slow call, use with caution */
export type SecurityInfo = {
	__typename: "SecurityInfo";
	lastPasswordChangeDateTime: Scalars["DateTime"]["output"];
	refreshTokensValidFromDateTime: Scalars["DateTime"]["output"];
};

export type ServiceHandle = "ADMIN_PORTAL" | "CNMIM";

export type ServicePermissions = {
	__typename: "ServicePermissions";
	permissions: Array<PermissionSchema>;
	serviceName: Scalars["String"]["output"];
};

export type StringFilter = {
	contains?: InputMaybe<Scalars["String"]["input"]>;
	equals?: InputMaybe<Scalars["String"]["input"]>;
	in?: InputMaybe<Array<Scalars["String"]["input"]>>;
	notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type TypeEnumFilter = {
	equals?: InputMaybe<DomainRuleType>;
	in?: InputMaybe<Array<DomainRuleType>>;
	not?: InputMaybe<DomainRuleType>;
	notIn?: InputMaybe<Array<DomainRuleType>>;
};

export type UnitOfWeight = "GRAMS" | "KILO_GRAMS" | "OUNCES" | "POUNDS";

export type UpdateRolesRelationInput = {
	connect?: InputMaybe<Array<ConnectRoleInput>>;
	disconnect?: InputMaybe<Array<ConnectRoleInput>>;
};

export type UpdateSelfDataInput = {
	companyName?: InputMaybe<Scalars["String"]["input"]>;
	companyNumberOfEmployees?: InputMaybe<Scalars["String"]["input"]>;
	companyType?: InputMaybe<Scalars["String"]["input"]>;
	country?: InputMaybe<Scalars["String"]["input"]>;
	defaultUnitOfWeight?: InputMaybe<UnitOfWeight>;
	givenName?: InputMaybe<Scalars["String"]["input"]>;
	jobTitle?: InputMaybe<Scalars["String"]["input"]>;
	newsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
	settings?: InputMaybe<UserSettingsUpdateInput>;
	surname?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateSelfInput = {
	data: UpdateSelfDataInput;
};

export type User = {
	__typename: "User";
	accountEnabled: Scalars["Boolean"]["output"];
	companyName?: Maybe<Scalars["String"]["output"]>;
	companyNumberOfEmployees?: Maybe<Scalars["String"]["output"]>;
	companyType?: Maybe<Scalars["String"]["output"]>;
	country: Scalars["String"]["output"];
	createdAt: Scalars["DateTime"]["output"];
	/** @deprecated See settings object */
	defaultUnitOfWeight: UnitOfWeight;
	email: Scalars["String"]["output"];
	givenName: Scalars["String"]["output"];
	group?: Maybe<Group>;
	id: Scalars["ID"]["output"];
	jobTitle?: Maybe<Scalars["String"]["output"]>;
	newsletter: Scalars["Boolean"]["output"];
	roles: Array<Role>;
	securityInfo: SecurityInfo;
	settings: UserSettings;
	surname: Scalars["String"]["output"];
	updatedAt: Scalars["DateTime"]["output"];
};

export type UserEdge = {
	__typename: "UserEdge";
	cursor: Scalars["String"]["output"];
	node: User;
};

export type UserFindManyWhereInput = {
	accountEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
	companyName?: InputMaybe<StringFilter>;
	companyNumberOfEmployees?: InputMaybe<StringFilter>;
	companyType?: InputMaybe<StringFilter>;
	country?: InputMaybe<StringFilter>;
	createdAt?: InputMaybe<DateTimeFilter>;
	defaultUnitOfWeight?: InputMaybe<DefaultunitofweightEnumFilter>;
	email?: InputMaybe<StringFilter>;
	givenName?: InputMaybe<StringFilter>;
	id?: InputMaybe<IdFilter>;
	jobTitle?: InputMaybe<StringFilter>;
	newsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
	surname?: InputMaybe<StringFilter>;
	updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserFindUniqueInput = {
	email?: InputMaybe<Scalars["String"]["input"]>;
	id?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserOrderByInput = {
	accountEnabled?: InputMaybe<OrderByDirection>;
	companyName?: InputMaybe<OrderByDirection>;
	companyNumberOfEmployees?: InputMaybe<OrderByDirection>;
	companyType?: InputMaybe<OrderByDirection>;
	country?: InputMaybe<OrderByDirection>;
	createdAt?: InputMaybe<OrderByDirection>;
	defaultUnitOfWeight?: InputMaybe<OrderByDirection>;
	email?: InputMaybe<OrderByDirection>;
	givenName?: InputMaybe<OrderByDirection>;
	id?: InputMaybe<OrderByDirection>;
	jobTitle?: InputMaybe<OrderByDirection>;
	newsletter?: InputMaybe<OrderByDirection>;
	surname?: InputMaybe<OrderByDirection>;
	updatedAt?: InputMaybe<OrderByDirection>;
};

export type UserReportFindManyWhereInput = {
	accountEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
	/** Timestamp applies to the activity record, not the user. Default value is beginning of the month */
	timestamp?: InputMaybe<DateTimeFilter>;
};

export type UserReportQueryOrderByInput = {
	lastLoggedIn?: InputMaybe<OrderByDirection>;
	surname?: InputMaybe<OrderByDirection>;
};

export type UserSettings = {
	__typename: "UserSettings";
	cnmimSettings?: Maybe<Scalars["JSON"]["output"]>;
	defaultUnitOfWeight: UnitOfWeight;
	paginationLimit: Scalars["Int"]["output"];
	servingSizeInGrams: Scalars["Float"]["output"];
};

export type UserSettingsUpdateInput = {
	cnmimSettings?: InputMaybe<Scalars["JSON"]["input"]>;
	defaultUnitOfWeight?: InputMaybe<UnitOfWeight>;
	paginationLimit?: InputMaybe<Scalars["Int"]["input"]>;
	servingSizeInGrams?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserUpdateFindManyInput = {
	emails?: InputMaybe<Array<Scalars["String"]["input"]>>;
	ids?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UserUpdateManyDataInput = {
	accountEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
	companyName?: InputMaybe<Scalars["String"]["input"]>;
	companyNumberOfEmployees?: InputMaybe<Scalars["String"]["input"]>;
	companyType?: InputMaybe<Scalars["String"]["input"]>;
	country?: InputMaybe<Scalars["String"]["input"]>;
	group?: InputMaybe<Scalars["String"]["input"]>;
	newsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
	roles?: InputMaybe<UpdateRolesRelationInput>;
};

export type UserUpdateManyInput = {
	data: UserUpdateManyDataInput;
	where: UserUpdateFindManyInput;
};

export type UserUpdateOneDataInput = {
	accountEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
	companyName?: InputMaybe<Scalars["String"]["input"]>;
	companyNumberOfEmployees?: InputMaybe<Scalars["String"]["input"]>;
	companyType?: InputMaybe<Scalars["String"]["input"]>;
	country?: InputMaybe<Scalars["String"]["input"]>;
	defaultUnitOfWeight?: InputMaybe<UnitOfWeight>;
	givenName?: InputMaybe<Scalars["String"]["input"]>;
	group?: InputMaybe<Scalars["String"]["input"]>;
	jobTitle?: InputMaybe<Scalars["String"]["input"]>;
	newsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
	/** Roles acts like a set, `null` will ignore this property for updating. `roles: ["handle"]` */
	roles?: InputMaybe<Array<Scalars["String"]["input"]>>;
	settings?: InputMaybe<UserSettingsUpdateInput>;
	surname?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserUpdateOneInput = {
	data: UserUpdateOneDataInput;
	where: UserFindUniqueInput;
};

export type WaterActivity = {
	__typename: "WaterActivity";
	a: Scalars["Float"]["output"];
	b: Scalars["Float"]["output"];
	lf: Scalars["Float"]["output"];
};

export type WaterActivityCreateInput = {
	a: Scalars["Float"]["input"];
	b: Scalars["Float"]["input"];
	lf: Scalars["Float"]["input"];
};

export type SettingsUpdateMutationVariables = Exact<{
	settings: UserSettingsUpdateInput;
}>;

export type SettingsUpdateMutation = {
	__typename: "Mutation";
	selfUpdate: { __typename: "User"; id: string };
};

export type HeaderProfileQueryVariables = Exact<{ [key: string]: never }>;

export type HeaderProfileQuery = {
	__typename: "Query";
	me: { __typename: "User"; id: string; givenName: string; surname: string; email: string };
};

export type PrivacyStatementAlertQueryVariables = Exact<{
	service: Scalars["String"]["input"];
}>;

export type PrivacyStatementAlertQuery = {
	__typename: "Query";
	activePrivacyStatement: {
		__typename: "PrivacyStatement";
		id: string;
		publishedAt?: string | null;
		version: number;
		content: string;
	};
};

export type CnmimCalculateSodiumQueryVariables = Exact<{
	formulas: Array<CnmimSodiumFormulaInput> | CnmimSodiumFormulaInput;
}>;

export type CnmimCalculateSodiumQuery = {
	__typename: "Query";
	CNMIMCalculateSodium: Array<{
		__typename: "CNMIMSodiumResult";
		formulationId: string;
		sodium: number;
	}>;
};

export type LogActivityMutationVariables = Exact<{
	input: ActivityCreateInput;
}>;

export type LogActivityMutation = { __typename: "Mutation"; logActivity: boolean };

export type SolveCalculationQueryVariables = Exact<{
	formulas: Array<CnmimMoldGrowthFormulaInput> | CnmimMoldGrowthFormulaInput;
	reference: CnmimMoldGrowthReferenceFormulaInput;
	regionHandle: Scalars["String"]["input"];
	productName: Scalars["String"]["input"];
	additionalInformation?: InputMaybe<Scalars["String"]["input"]>;
	foodType?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SolveCalculationQuery = {
	__typename: "Query";
	CNMIMCalculateMoldGrowth: Array<{
		__typename: "CNMIMMoldGroupGrowthResults";
		id: string;
		moldGroupResults: Array<{
			__typename: "CNMIMMoldGroupGrowthResult";
			type: MoldGroup;
			minPredictedDays: number;
			warning: boolean;
			moldGrowthFormulations?: Array<{
				__typename: "CNMIMMoldGrowthResult";
				id: string;
				isolatedFrom: string;
				predictedDays: number;
				name: string;
				strainCode: string;
				warning: boolean;
			}> | null;
		}>;
	}>;
};

export type PermissionsHaveChangedQueryVariables = Exact<{ [key: string]: never }>;

export type PermissionsHaveChangedQuery = {
	__typename: "Query";
	myPermissionsHaveChanged: boolean;
};

export type PrivacyStatementHasAcceptedQueryVariables = Exact<{ [key: string]: never }>;

export type PrivacyStatementHasAcceptedQuery = {
	__typename: "Query";
	hasAcceptedPrivacyStatement: boolean;
};

export type AcceptPrivacyStatementMutationVariables = Exact<{ [key: string]: never }>;

export type AcceptPrivacyStatementMutation = {
	__typename: "Mutation";
	acceptPrivacyStatement: boolean;
};

export type PortfolioProductsQueryVariables = Exact<{
	regionHandle: Scalars["String"]["input"];
}>;

export type PortfolioProductsQuery = {
	__typename: "Query";
	CNMIMPortfolio: Array<
		| { __typename: "AntimicrobialProduct"; id: string; name: string }
		| { __typename: "CorbionProduct"; id: string; name: string }
		| { __typename: "PrototypeProduct"; id: string; name: string }
		| { __typename: "PureProduct"; id: string; name: string }
	>;
};

export type SettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsQuery = {
	__typename: "Query";
	me: {
		__typename: "User";
		id: string;
		settings: {
			__typename: "UserSettings";
			defaultUnitOfWeight: UnitOfWeight;
			servingSizeInGrams: number;
			cnmimSettings?: object | null;
		};
	};
};

export type CnmimReferenceFormulaDefaultDaysToVisualMoldGrowthQueryVariables = Exact<{
	[key: string]: never;
}>;

export type CnmimReferenceFormulaDefaultDaysToVisualMoldGrowthQuery = {
	__typename: "Query";
	CNMIMReferenceFormulaDefaultDaysToVisualMoldGrowth: number;
};

export type ConfigureRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigureRegionsQuery = {
	__typename: "Query";
	regions: Array<{ __typename: "Region"; id: string; handle: string; name: string }>;
};

export type ActivePrivacyStatementQueryVariables = Exact<{
	service: Scalars["String"]["input"];
}>;

export type ActivePrivacyStatementQuery = {
	__typename: "Query";
	activePrivacyStatement: {
		__typename: "PrivacyStatement";
		id: string;
		publishedAt?: string | null;
		version: number;
		content: string;
	};
};

export type ResultProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ResultProfileQuery = {
	__typename: "Query";
	me: { __typename: "User"; id: string; givenName: string; surname: string };
};

export type PortfolioListQueryVariables = Exact<{ [key: string]: never }>;

export type PortfolioListQuery = {
	__typename: "Query";
	CNMIMPortfolio: Array<
		| { __typename: "AntimicrobialProduct"; id: string; name: string }
		| { __typename: "CorbionProduct"; id: string; name: string }
		| { __typename: "PrototypeProduct"; id: string; name: string }
		| { __typename: "PureProduct"; id: string; name: string }
	>;
};

export const SettingsUpdateDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "SettingsUpdate" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "settings" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "UserSettingsUpdateInput" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "selfUpdate" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "data" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "settings" },
														value: { kind: "Variable", name: { kind: "Name", value: "settings" } },
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SettingsUpdateMutation, SettingsUpdateMutationVariables>;
export const HeaderProfileDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "HeaderProfile" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "me" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "givenName" } },
								{ kind: "Field", name: { kind: "Name", value: "surname" } },
								{ kind: "Field", name: { kind: "Name", value: "email" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<HeaderProfileQuery, HeaderProfileQueryVariables>;
export const PrivacyStatementAlertDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "PrivacyStatementAlert" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "service" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "activePrivacyStatement" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "service" },
								value: { kind: "Variable", name: { kind: "Name", value: "service" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "publishedAt" } },
								{ kind: "Field", name: { kind: "Name", value: "version" } },
								{ kind: "Field", name: { kind: "Name", value: "content" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PrivacyStatementAlertQuery, PrivacyStatementAlertQueryVariables>;
export const CnmimCalculateSodiumDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "CNMIMCalculateSodium" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "formulas" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: { kind: "Name", value: "CNMIMSodiumFormulaInput" },
								},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "CNMIMCalculateSodium" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "formulas" },
								value: { kind: "Variable", name: { kind: "Name", value: "formulas" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "formulationId" } },
								{ kind: "Field", name: { kind: "Name", value: "sodium" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CnmimCalculateSodiumQuery, CnmimCalculateSodiumQueryVariables>;
export const LogActivityDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "LogActivity" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ActivityCreateInput" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "logActivity" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: { kind: "Variable", name: { kind: "Name", value: "input" } },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<LogActivityMutation, LogActivityMutationVariables>;
export const SolveCalculationDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "SolveCalculation" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "formulas" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: { kind: "Name", value: "CNMIMMoldGrowthFormulaInput" },
								},
							},
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "reference" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "CNMIMMoldGrowthReferenceFormulaInput" },
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "regionHandle" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "productName" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "additionalInformation" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "foodType" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "CNMIMCalculateMoldGrowth" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "formulas" },
								value: { kind: "Variable", name: { kind: "Name", value: "formulas" } },
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "reference" },
								value: { kind: "Variable", name: { kind: "Name", value: "reference" } },
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "regionHandle" },
								value: { kind: "Variable", name: { kind: "Name", value: "regionHandle" } },
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "productName" },
								value: { kind: "Variable", name: { kind: "Name", value: "productName" } },
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "additionalInformation" },
								value: { kind: "Variable", name: { kind: "Name", value: "additionalInformation" } },
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "foodType" },
								value: { kind: "Variable", name: { kind: "Name", value: "foodType" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "moldGroupResults" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "type" } },
											{ kind: "Field", name: { kind: "Name", value: "minPredictedDays" } },
											{ kind: "Field", name: { kind: "Name", value: "warning" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "moldGrowthFormulations" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "id" } },
														{ kind: "Field", name: { kind: "Name", value: "isolatedFrom" } },
														{ kind: "Field", name: { kind: "Name", value: "predictedDays" } },
														{ kind: "Field", name: { kind: "Name", value: "name" } },
														{ kind: "Field", name: { kind: "Name", value: "strainCode" } },
														{ kind: "Field", name: { kind: "Name", value: "warning" } },
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SolveCalculationQuery, SolveCalculationQueryVariables>;
export const PermissionsHaveChangedDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "PermissionsHaveChanged" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [{ kind: "Field", name: { kind: "Name", value: "myPermissionsHaveChanged" } }],
			},
		},
	],
} as unknown as DocumentNode<PermissionsHaveChangedQuery, PermissionsHaveChangedQueryVariables>;
export const PrivacyStatementHasAcceptedDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "PrivacyStatementHasAccepted" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "hasAcceptedPrivacyStatement" } },
				],
			},
		},
	],
} as unknown as DocumentNode<
	PrivacyStatementHasAcceptedQuery,
	PrivacyStatementHasAcceptedQueryVariables
>;
export const AcceptPrivacyStatementDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "AcceptPrivacyStatement" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [{ kind: "Field", name: { kind: "Name", value: "acceptPrivacyStatement" } }],
			},
		},
	],
} as unknown as DocumentNode<
	AcceptPrivacyStatementMutation,
	AcceptPrivacyStatementMutationVariables
>;
export const PortfolioProductsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "PortfolioProducts" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "regionHandle" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "CNMIMPortfolio" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "regionAvailable" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "handle" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "regionHandle" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PortfolioProductsQuery, PortfolioProductsQueryVariables>;
export const SettingsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "Settings" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "me" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "settings" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "defaultUnitOfWeight" } },
											{ kind: "Field", name: { kind: "Name", value: "servingSizeInGrams" } },
											{ kind: "Field", name: { kind: "Name", value: "cnmimSettings" } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SettingsQuery, SettingsQueryVariables>;
export const CnmimReferenceFormulaDefaultDaysToVisualMoldGrowthDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "CNMIMReferenceFormulaDefaultDaysToVisualMoldGrowth" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "CNMIMReferenceFormulaDefaultDaysToVisualMoldGrowth" },
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	CnmimReferenceFormulaDefaultDaysToVisualMoldGrowthQuery,
	CnmimReferenceFormulaDefaultDaysToVisualMoldGrowthQueryVariables
>;
export const ConfigureRegionsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "ConfigureRegions" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "regions" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "handle" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ConfigureRegionsQuery, ConfigureRegionsQueryVariables>;
export const ActivePrivacyStatementDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "ActivePrivacyStatement" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "service" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "activePrivacyStatement" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "service" },
								value: { kind: "Variable", name: { kind: "Name", value: "service" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "publishedAt" } },
								{ kind: "Field", name: { kind: "Name", value: "version" } },
								{ kind: "Field", name: { kind: "Name", value: "content" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ActivePrivacyStatementQuery, ActivePrivacyStatementQueryVariables>;
export const ResultProfileDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "ResultProfile" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "me" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "givenName" } },
								{ kind: "Field", name: { kind: "Name", value: "surname" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ResultProfileQuery, ResultProfileQueryVariables>;
export const PortfolioListDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "PortfolioList" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "CNMIMPortfolio" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PortfolioListQuery, PortfolioListQueryVariables>;
