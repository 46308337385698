<template>
	<header
		v-bind="$attrs"
		class="z-50 flex min-w-0 bg-gradient-to-r from-brand-secondary-darker to-brand-secondary md:h-28"
	>
		<router-link
			class="min-h-16 w-1/12 min-w-16 max-w-56 bg-white p-2 md:w-1/6 md:p-6"
			:to="{
				name: isAuthenticated
					? router.currentRoute.value.name === 'Calculate'
						? 'Calculate'
						: 'Configure'
					: 'Authentication',
			}"
		>
			<sb-logo />
		</router-link>

		<div
			class="flex min-w-0 flex-1 items-center justify-between gap-4 bg-[url(/assets/img/top_bg.png)] bg-right bg-no-repeat px-8 py-4 text-white"
		>
			<div class="items-startt flex min-w-0 flex-col">
				<h1 class="w-full min-w-0 truncate text-2xl font-bold lg:text-3xl xl:text-4xl">
					Corbion Natural Mold Inhibition Model
				</h1>
				<small class="hidden w-full min-w-0 truncate text-base text-white/40 md:block">
					Biobased ingredients for shelf life, freshness and food safety
				</small>
			</div>

			<div class="hidden min-w-0 flex-1 flex-col items-end gap-2 lg:flex">
				<Button
					v-if="isAuthenticated"
					variant="link"
					size="auto"
					class="max-w-full justify-end gap-2 text-base text-white"
					@click="signOut"
				>
					<LogOut class="size-3 min-w-3 rotate-180" />
					<span class="truncate">
						Log out
						<template v-if="profile">
							{{ profile.givenName }} {{ profile.surname }} ({{ profile.email }})
						</template>
					</span>
				</Button>
				<Button
					v-else
					variant="link"
					size="auto"
					class="max-w-full gap-2 truncate text-base text-white"
					@click="signIn"
				>
					<LogIn class="size-3 min-w-3" />
					<span class="truncate"> Log in </span>
				</Button>

				<div class="flex flex-wrap items-center justify-end gap-x-3 gap-y-1">
					<Button
						v-if="havePermissionsChanged"
						variant="link"
						size="auto"
						class="relative me-4 text-base text-white"
						@click="updatePermissions"
					>
						Update permissions
						<span class="absolute -end-1 top-0 flex size-2">
							<span
								class="absolute size-full animate-ping rounded-full bg-brand-primary-lighter opacity-75"
							/>
							<span class="relative size-full rounded-full bg-brand-primary-lighter" />
						</span>
					</Button>
					<ButtonModalTutorials
						v-if="isAuthenticated"
						v-model:open="tutorialsOpen"
						class="text-white"
					/>
					<Button
						:as="RouterLink"
						:to="{ name: 'FAQ' }"
						variant="link"
						size="auto"
						class="text-base text-white"
					>
						FAQ
					</Button>
					<Button
						as="a"
						href="https://www.corbion.com/Markets/Food/Bakery"
						variant="link"
						size="auto"
						class="text-base text-white"
					>
						About us
					</Button>
					<Button
						as="a"
						:href="`mailto:${clientEnv.VITE_CORBION_SUPPORT_EMAIL}`"
						variant="link"
						size="auto"
						class="text-base text-white"
					>
						Contact
					</Button>
				</div>
			</div>

			<Sheet>
				<SheetTrigger as-child>
					<Button size="icon" class="flex lg:hidden" aria-label="Open menu" variant="naked">
						<Menu class="size-6" />
					</Button>
				</SheetTrigger>
				<SheetContent
					side="top"
					class="flex flex-col bg-gradient-to-r from-brand-secondary-darker to-brand-secondary"
				>
					<div
						class="flex flex-col divide-y divide-solid divide-brand-gray/30 *:rounded-none *:border-x-0 *:py-4 focus-visible:*:rounded-lg"
					>
						<SheetClose as-child>
							<Button
								v-if="isAuthenticated"
								variant="link"
								size="auto"
								class="max-w-full gap-2 text-2xl text-white"
								@click="signOut"
							>
								<LogOut class="size-3 min-w-3 rotate-180" />
								<span class="truncate">
									Log out
									<template v-if="profile">
										{{ profile.givenName }} {{ profile.surname }} ({{ profile.email }})
									</template>
								</span>
							</Button>
							<Button
								v-else
								variant="link"
								size="auto"
								class="max-w-full gap-2 text-2xl text-white"
								@click="signIn"
							>
								<LogIn class="size-3 min-w-3" />
								<span class="truncate"> Log in </span>
							</Button>
						</SheetClose>

						<SheetClose v-if="havePermissionsChanged" as-child>
							<Button
								variant="link"
								size="auto"
								class="text-2xl text-white"
								@click="updatePermissions"
							>
								<span class="relative">
									Update permissions
									<span class="absolute -end-1.5 top-0 flex size-2.5">
										<span
											class="absolute size-full animate-ping rounded-full bg-brand-primary-lighter opacity-75"
										/>
										<span class="relative size-full rounded-full bg-brand-primary-lighter" />
									</span>
								</span>
							</Button>
						</SheetClose>

						<SheetClose v-if="isAuthenticated" as-child>
							<Button
								variant="link"
								size="auto"
								class="gap-2 text-2xl text-white"
								@click="tutorialsOpen = true"
							>
								Tutorials
							</Button>
						</SheetClose>

						<SheetClose as-child>
							<Button
								:as="RouterLink"
								:to="{ name: 'FAQ' }"
								variant="link"
								size="auto"
								class="text-2xl text-white"
							>
								FAQ
							</Button>
						</SheetClose>

						<SheetClose as-child>
							<Button
								as="a"
								href="https://www.corbion.com/Markets/Food/Bakery"
								variant="link"
								size="auto"
								class="text-2xl text-white"
							>
								About us
							</Button>
						</SheetClose>

						<SheetClose as-child>
							<Button
								as="a"
								:href="`mailto:${clientEnv.VITE_CORBION_SUPPORT_EMAIL}`"
								variant="link"
								size="auto"
								class="text-2xl text-white"
							>
								Contact
							</Button>
						</SheetClose>
					</div>
				</SheetContent>
			</Sheet>
		</div>
	</header>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useIsAuthenticated } from "@stijlbreuk/msal-vue";
import { LogIn, LogOut, Menu } from "lucide-vue-next";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { RouterLink } from "vue-router";
import ButtonModalTutorials from "@/components/ButtonModal/Tutorials.vue";
import SbLogo from "@/components/atoms/Logo.vue";
import { Button } from "@/components/ui/button";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { graphql } from "@/graphql";
import { clientEnv } from "@/lib/env/client";
import { msalInstance } from "@/lib/services/msal/instance";
import { msalRequest } from "@/lib/services/msal/request";
import { signIn, signOut } from "@/store/auth";
import { usePermissionsStore } from "@/store/permissions";

const tutorialsOpen = ref<boolean>(false);

const isAuthenticated = useIsAuthenticated();
const { haveChanged: havePermissionsChanged } = storeToRefs(usePermissionsStore());
const profileQuery = useQuery(
	graphql(`
		query HeaderProfile {
			me {
				id
				givenName
				surname
				email
			}
		}
	`),
	null,
	{ enabled: isAuthenticated },
);
const profile = computed(() => profileQuery.result.value?.me);
const router = useRouter();

async function updatePermissions() {
	return await msalInstance.logoutRedirect({
		...msalRequest,
		postLogoutRedirectUri: "/redirect/sign-in",
	});
}
</script>
