<template>
	<div class="sb_fixed-action-bar [--input-widths:580px] compact:[--input-widths:840px]">
		<div
			v-if="router.currentRoute.value.name === 'Configure region'"
			class="sb_fixed-action-bar--configure"
		>
			<Button
				class="gap-2 bg-brand-primary-lighter text-base hover:bg-brand-primary-lighter/90"
				:disabled="!region"
				@click="router.push({ name: 'Configure product' })"
			>
				Next
				<ChevronRight class="size-4" />
			</Button>
		</div>
		<div
			v-else-if="router.currentRoute.value.name === 'Configure product'"
			class="sb_fixed-action-bar--configure"
		>
			<Button
				class="gap-2 bg-brand-primary-lighter text-base hover:bg-brand-primary-lighter/90"
				:disabled="!foodType"
				@click="router.push({ name: 'Calculate' })"
			>
				Next
				<ChevronRight class="size-4" />
			</Button>
			<Button
				variant="naked"
				class="gap-2 text-base"
				:disabled="!foodType"
				@click="router.push({ name: 'Configure region' })"
			>
				<ChevronLeft class="size-4" />
				Previous
			</Button>
		</div>
		<div
			v-else-if="router.currentRoute.value.name === 'Calculate'"
			class="sb_fixed-action-bar--calculate sb_fixed-action-bar_inner"
		>
			<div class="sb_fixed-action-bar_inputs">
				<ButtonModalSettings />

				<ConditionalTooltip
					:shown="!canCalculate || formulations.length <= 1"
					:class="cx(!canCalculate && 'cursor-not-allowed')"
				>
					<Button
						class="gap-2 bg-brand-primary-lighter text-base hover:bg-brand-primary-lighter/90"
						:disabled="calculation.calculating || !canCalculate || formulations.length <= 1"
						@click="calculate"
					>
						Calculate
						<LoadingIcon v-if="calculation.calculating" class="size-4" />
						<ChevronRight v-else class="size-4" />
					</Button>

					<template #content>
						<template v-if="!canCalculate">
							You do not have permission to calculate formulations
						</template>
						<template v-else-if="formulations.length <= 1">
							You need at least 1 formulation other than the reference formulation
						</template>
					</template>
				</ConditionalTooltip>
			</div>

			<div class="sb_fixed-action-bar_results"></div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ChevronLeft, ChevronRight } from "lucide-vue-next";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import ButtonModalSettings from "@/components/ButtonModal/Settings.vue";
import ConditionalTooltip from "@/components/ConditionalTooltip.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { Button } from "@/components/ui/button";
import { cx } from "@/lib/style/cva.config";
import { solveCalculation, validateCalculation } from "@/store/calculation/actions";
import { calculation } from "@/store/calculation/state";
import { useConfigurationStore } from "@/store/configuration";
import { useFormulationsStore } from "@/store/formulations";
import { usePermissionsStore } from "@/store/permissions";

const { calculationFormulations: formulations } = storeToRefs(useFormulationsStore());
const { region, foodType } = storeToRefs(useConfigurationStore());
const permissionsStore = usePermissionsStore();

const router = useRouter();
const canCalculate = computed(() => permissionsStore.getValue("cnmim.formulation.solve") > 0);

async function calculate() {
	const { success } = await validateCalculation();

	if (!success) {
		return;
	}

	await solveCalculation();

	document
		.querySelector("[data-shepherd-results]")
		?.scrollIntoView({ inline: "nearest", block: "nearest", behavior: "smooth" });
}
</script>

<style lang="scss">
.sb_fixed-action-bar {
	--transition-duration: #{$transition-duration * 2};

	position: fixed;
	inset: auto 0 0 0;
	width: 100%;
	height: 60px;
	z-index: 3;
	background: $brand-white;
	box-shadow: 0 0 $padding * 4 rgba($brand-primary-darker, 0.1);

	&--configure {
		height: 100%;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
		margin-inline: auto;
		padding-inline: $padding;
		max-width: 100%;
		width: 100ch;
	}

	&_inner {
		max-width: 1680px;
		margin-inline: auto;
		padding-inline: $padding;
		display: flex;
		gap: $padding;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		will-change: transform;
		transition-property: transform;
		transition-timing-function: $transition-timing-function;
		transition-duration: var(--transition-duration);
		height: 100%;
	}

	&_inputs {
		min-width: var(--input-widths);
		width: var(--input-widths);
		max-width: var(--input-widths);
		will-change: transform, min-width, width, max-width;
		transition-property: transform, min-width, width, max-width;
		transition-timing-function: $transition-timing-function;
		transition-duration: var(--transition-duration);
		height: $padding * 2.5;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	&_results {
		height: $padding * 2.5;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: $padding * 0.5;
		color: $brand-error;
		width: auto;
		flex-grow: 1;
		will-change: width, transform, opacity;
		transition-property: width, transform, opacity;
		transition-timing-function: $transition-timing-function;
		transition-duration: var(--transition-duration);
	}

	@include media("<=xl") {
		.sb_fixed-action-bar {
			&_inner {
				transform: none;
				max-width: max(80vw, 600px);
			}

			&_inputs {
				--input-widths: 100%;

				transform: none;
			}

			&_results {
				display: none;
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
}

[data-results="hidden"] {
	.sb_fixed-action-bar {
		&_inner {
			transform: translateX(50%);

			@include media("<=xl") {
				transform: none;
			}
		}

		&_inputs {
			transform: translateX(calc(-50% + #{$padding * -1}));

			@include media("<=xl") {
				--input-widths: 100%;

				transform: none;
			}
		}

		&_results {
			opacity: 0;
			pointer-events: none;

			@include media("<=xl") {
				display: none;
			}
		}
	}
}
</style>
