import { computed, ref } from "vue";
import { AuthError, type AuthenticationResult, EventType } from "@azure/msal-browser";
import { type JwtPayload, jwtDecode } from "jwt-decode";
import { defineStore } from "pinia";
import { CustomNavigationClient } from "@/lib/packages/@stijlbreuk/msal-vue/router/NavigationClient";
import { getToken } from "@/lib/services/msal/getToken";
import { msalInstance } from "@/lib/services/msal/instance";
import { msalOptions } from "@/lib/services/msal/options";
import { msalRequest } from "@/lib/services/msal/request";
import { router } from "@/router";
import { usePermissionsStore } from "@/store/permissions";

export enum KnownErrorCode {
	CredentialsInvalid = "AADB2C90027",
	GeneralError = "AADB2C90075",
	UserCancellation = "AADB2C90091",
}

type KnownError = {
	code: KnownErrorCode;
	title: string;
	text: string;
};

const knownErrors: KnownError[] = [
	{
		code: KnownErrorCode.UserCancellation,
		title: "Action was cancelled",
		text: "While either trying to register or reset your password, the action was cancelled. Please try again.",
	},
	{
		code: KnownErrorCode.CredentialsInvalid,
		title: "Failed to sign in",
		text: "We could not sign you in. Please contact us for support.",
	},
	{
		code: KnownErrorCode.GeneralError,
		title: "Something went wrong...",
		text: "There was a problem processing your request. Please try again.",
	},
];

export const useAuthStore = defineStore(
	"auth",
	() => {
		const token = ref<AuthenticationResult>();

		const error = ref<AuthError | Error>();
		const errorMessage = computed(() => {
			const errorValue = error.value;

			if (!errorValue) {
				return;
			}

			if (!(errorValue instanceof AuthError)) {
				return;
			}

			return knownErrors.find((knownError) => errorValue.errorMessage.startsWith(knownError.code));
		});

		msalInstance.setNavigationClient(new CustomNavigationClient(router));
		msalInstance.enableAccountStorageEvents();

		const activeAccount = msalInstance.getActiveAccount();

		if (activeAccount) {
			getToken(msalInstance);
		} else {
			const firstAccount = msalInstance.getAllAccounts()[0];

			if (firstAccount) {
				msalInstance.setActiveAccount(firstAccount);
				getToken(msalInstance);
			}
		}

		msalInstance.addEventCallback((event) => {
			if (event.error) {
				error.value = event.error;
			}

			switch (event.eventType) {
				case EventType.LOGIN_SUCCESS:
				case EventType.SSO_SILENT_SUCCESS:
				case EventType.ACQUIRE_TOKEN_SUCCESS: {
					const payload = event.payload as AuthenticationResult;

					msalInstance.setActiveAccount(payload.account);

					const decoded = jwtDecode<JwtPayload & { isForgotPassword?: unknown }>(
						payload.accessToken,
					);

					if (decoded?.["isForgotPassword"]) {
						msalInstance.logoutRedirect({
							postLogoutRedirectUri: "/redirect/sign-in",
						});
					} else {
						token.value = payload;
					}

					break;
				}

				case EventType.LOGOUT_SUCCESS:
				case EventType.ACQUIRE_TOKEN_FAILURE: {
					usePermissionsStore().$patch({ alertDismissed: undefined });
					token.value = undefined;
					break;
				}
			}
		});

		return { token, error, errorMessage };
	},
	{ persist: { pick: ["token"] } },
);

export async function signIn() {
	return await msalInstance.loginRedirect({
		...msalRequest,
		redirectStartPage: "/configure",
	});
}

export async function forgotPassword() {
	return await msalInstance.loginRedirect({
		...msalRequest,
		redirectStartPage: "/configure",
		authority: [msalOptions.domain, msalOptions.passwordResetAuthority].join(""),
	});
}

export async function register() {
	return await msalInstance.loginRedirect({
		...msalRequest,
		redirectStartPage: "/configure",
		authority: [msalOptions.domain, msalOptions.registerAuthority].join(""),
	});
}

export async function signOut() {
	return await msalInstance.logoutRedirect({
		postLogoutRedirectUri: "/",
	});
}
